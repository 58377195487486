import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { cn } from 'util/index';

// pc
const Section01 = () => {
  return (
    <section className='w-[1200px] mx-auto py-[70px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          ALL IN ONE CARSAYO
        </motion.span>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700]'
        >
          자동차 쇼핑 통합 플랫폼
        </motion.strong>
      </div>
      <div className='w-full flex flex-col justify-start items-start gap-[20px]'>
        <div className='flex justify-start items-start gap-[40px]'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            className='w-[600px] h-[280px]'
          >
            <img
              src='../imgs/png/company/philosophy_section01_bg.png'
              alt='고객의경험을풍요롭게'
              className='w-full object-cover'
            />
          </motion.div>
          <div className='py-[40px]'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='text-[20px] leading-[20px] font-[600] inline-block mb-6'
            >
              고객의 경험을{' '}
              <strong className='text-[#6E77F6] font-[600]'>풍요롭게</strong>
            </motion.span>
            <motion.p
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
            >
              안전하고 편리한 자동차 생활 서비스를 제공하여
              <br />
              고객이 합리적으로 선택할 수 있는 국민 기업으로 성장하고 있습니다.
            </motion.p>
          </div>
        </div>
        <div className='flex justify-start items-start gap-[40px]'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            className='w-[600px] h-[280px]'
          >
            <img
              src='../imgs/png/company/philosophy_section01_bg02.png'
              alt='지속발전가능한바른경영'
              className='w-full object-cover'
            />
          </motion.div>
          <div className='py-[40px]'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='text-[20px] leading-[20px] font-[600] inline-block mb-6'
            >
              지속 발전 가능한{' '}
              <strong className='text-[#6E77F6] font-[600]'>바른 경영</strong>
            </motion.span>
            <motion.p
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
            >
              혁신적인 기술과 솔루션을 제공하는 자동차 업계 리더로서
              <br />
              바른 경영을 통해 지속 가능한 미래를 만들겠습니다.
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  );
};
const Section02 = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 0.5 },
      }}
      style={{
        backgroundImage: 'url(../imgs/png/company/philosophy_section02_bg.png)',
      }}
      className='bg-no-repeat bg-cover bg-center h-[600px] mt-[100px]'
    >
      <div className='w-[1200px] mx-auto'>
        <div className='w-full text-white py-[60px] flex justify-between items-center'>
          <span className='text-[26px] leading-[26px] font-[600]'>
            자동차 업계의 리더이기에
          </span>
          <ul className='relative flex justify-center items-center gap-[10px] -mr-[25px] text-[18px] leading-[28.8px] font-[500]'>
            <li className='w-[257px] h-[152px] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF33]'>
              창의적인 변화와 도전
            </li>
            <li className='w-[257px] h-[152px] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF33]'>
              신뢰하는 소통과 협력
            </li>
            <li className='w-[257px] h-[152px] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF33]'>
              지속적인 발전과 상생
            </li>
            <span className='absolute w-[174px] h-[1px] bg-white -left-[150px]'></span>
          </ul>
        </div>
      </div>
    </motion.section>
  );
};

// 모바일
const MSection01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section className='w-full pt-[50px] px-[20px]'>
      <div className='mb-[30px] flex flex-col justify-start items-start gap-[6px]'>
        <span
          className={cn(
            'text-[#6E77F6] text-[14px] leading-[14px] font-[600] transition-all duration-500',
            layoutLoad
              ? 'translate-y-0 opacity-100'
              : '-translate-y-[20px] opacity-20'
          )}
        >
          ALL IN ONE CARSAYO
        </span>
        <strong
          className={cn(
            'text-[20px] leading-[30px] font-[600] break-keep transition-all duration-500 delay-[10ms]',
            layoutLoad
              ? 'translate-y-0 opacity-100'
              : '-translate-y-[20px] opacity-20'
          )}
        >
          자동차 쇼핑 통합 플랫폼
        </strong>
      </div>
      <div className='w-full flex flex-col justify-start items-start gap-[50px]'>
        <div className='flex flex-col justify-start items-start gap-6'>
          <div className={cn('w-full aspect-[335/220]')}>
            <img
              src='../imgs/png/company/philosophy_section01_bg.png'
              alt='고객의경험을풍요롭게'
              className={cn(
                'w-full h-full object-cover  transition-all duration-75',
                layoutLoad ? 'opacity-100' : 'opacity-20'
              )}
            />
          </div>
          <div>
            <span
              className={cn(
                'text-[16px] leading-[16px] font-[600] inline-block mb-4 transition-all duration-500 delay-[30ms]',
                layoutLoad
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-[20px] opacity-20'
              )}
            >
              고객의 경험을{' '}
              <strong className='text-[#6E77F6] font-[600]'>풍요롭게</strong>
            </span>
            <p
              className={cn(
                'leading-[25.6px] max-w-[325px] break-keep transition-all duration-500 delay-[40ms]',
                layoutLoad
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-[20px] opacity-20'
              )}
            >
              안전하고 편리한 자동차 생활 서비스를 제공하여 고객이 합리적으로
              선택할 수 있는 국민 기업으로 성장하고 있습니다.
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-start items-start gap-6'>
          <div className='w-full aspect-[335/220]'>
            <img
              src='../imgs/png/company/philosophy_section01_bg02.png'
              alt='지속발전가능한바른경영'
              className='w-full h-full object-cover'
            />
          </div>
          <div>
            <span className='text-[16px] leading-[16px] font-[600] inline-block mb-4'>
              지속 발전 가능한{' '}
              <strong className='text-[#6E77F6] font-[600]'>바른 경영</strong>
            </span>
            <p className='leading-[25.6px] max-w-[325px] break-keep'>
              혁신적인 기술과 솔루션을 제공하는 자동차 업계 리더로서 바른 경영을
              통해 지속 가능한 미래를 만들겠습니다.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const MSection02 = () => {
  return (
    <section
      style={{
        backgroundImage:
          'url(../imgs/png/company/m_philosophy_section02_bg.png)',
      }}
      className='bg-no-repeat bg-cover bg-center w-full aspect-[375/600] min-h-[600px] mt-[50px]'
    >
      <div className='w-full'>
        <div className='w-full text-white py-[60px] flex flex-col justify-start items-center gap-[64px]'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='text-[20px] leading-[20px] font-[600]'
          >
            자동차 업계의 리더이기에
          </motion.span>
          <ul className='relative w-[80%] flex flex-col justify-center items-center gap-[10px] text-[16px] leading-[25.6px] font-[500]'>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='w-full aspect-[274/64] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF80]'
            >
              창의적인 변화와 도전
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='w-full aspect-[274/64] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF80]'
            >
              신뢰하는 소통과 협력
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='w-full aspect-[274/64] flex justify-center items-center bg-[#FFFFFF0D] backdrop-blur-[20px] backdrop:brightness-100 border border-[#FFFFFF80]'
            >
              지속적인 발전과 상생
            </motion.li>
            <span className='absolute h-[51px] w-[1px] bg-white left-0 right-0 mx-auto -top-[40px]'></span>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default function Philosophy() {
  return (
    <>
      <BrowserView>
        <Section01 />
        <Section02 />
      </BrowserView>
      <MobileView className='w-dvw'>
        <MSection01 />
        <MSection02 />
      </MobileView>
    </>
  );
}

import { isBrowser, isMobile } from 'react-device-detect';
import BrowserCCMPage from './browser';
import MobileCCMPage from './mobile';

export default function CCMPage() {
  return (
    <>
      {isBrowser && <BrowserCCMPage />}
      {isMobile && <MobileCCMPage />}
    </>
  );
}

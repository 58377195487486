import { cn } from 'util/index';
import { list } from '../main';
import { motion } from 'framer-motion';

export default function MSection02() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 0.7 },
      }}
      className='w-[100dvw] pt-[50px] px-[20px]'
    >
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: { delay: 0, duration: 0.5 },
        }}
        className='pl-[10px] text-[20px] leading-[30px] font-[600] mb-[20px]'
      >
        카사요는 왜 다른가요?
      </motion.div>
      <ul>
        {list.map((item, index) => (
          <motion.li
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            key={`section02List_${item.title}`}
            className={cn(
              'p-6 min-h-[280px] flex flex-col justify-start items-start relative gap-[10px] bg-[#F8F9FA] border-[#EEEEEE] border-b last:border-b-0'
            )}
          >
            <span className='min-w-[46px] mb-2.5 text-[15px] leading-[15px] font-[700] inline-flex justify-center items-center py-[6px] px-[11px] bg-[#222] rounded-[100px] text-white'>
              0{index + 1}
            </span>
            <div className='w-full relative z-[1]'>
              <strong className='text-[20px] leading-[32px] font-[600]'>
                {item.title}
              </strong>
              {item.point && (
                <span className='mt-[5.5px] flex justify-start items-center pl-2 text-[#000] border-[#222] border-l-[3px] text-[16px] leading-[19px] font-[500]'>
                  {item.point}
                </span>
              )}
            </div>
            <p
              className={cn(
                'text-[16px] leading-[25.6px] font-[400] break-keep max-w-[270px] whitespace-pre-line relative z-[1]',
                index === list.length - 1 && 'max-w-[250px] whitespace-normal'
              )}
            >
              {item.description}
            </p>
            <div
              className={cn(
                'flex flex-col justify-center items-center gap-[12px] absolute bottom-[4px] right-[16px] z-0',
                index === list.length - 1 && 'bottom-0'
              )}
            >
              <img
                className='h-[58px] object-contain'
                src={item.imgUrl}
                alt={`${item.title}_이미지`}
              />
              <img
                src='../imgs/png/main_section02_shadow.png'
                alt='그림자'
                className='w-[60px] object-contain'
              />
            </div>
          </motion.li>
        ))}
      </ul>
    </motion.section>
  );
}

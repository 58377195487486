import { isBrowser, isMobile } from 'react-device-detect';

function BrowserServiceUsage() {
  return (
    <div className='relative flex-grow flex flex-col items-center justify-center w-full h-full py-[2.5%] min-h-[1000px] min-w-[1260px]'>
      <iframe
        src={'https://app.carsayo.net/'}
        title='카사요'
        className='rounded-[18.1px] shadow-[0px_0px_0px_13.6px_#0000001A] bg-[#FFFFFF] aspect-[340/736] h-[736px] z-[1]'
      ></iframe>

      <div className='absolute w-full h-full'>
        <div
          style={{
            backgroundImage: 'url("/imgs/png/service/cloud_bg_1.png")',
          }}
          className='absolute bottom-0 w-full h-full bg-[#EDF2FD] bg-no-repeat bg-cover bg-top'
        ></div>
        <div className='w-full h-full bg-[#A2BFFF] mix-blend-hue'></div>
        <img
          src={'/imgs/png/service/hand_1.png'}
          alt='주요기록이미지'
          className='right-0 top-0 absolute object-cover transition-all max-h-[35%]'
        />
        <img
          src={'/imgs/png/service/hand_2.png'}
          alt='주요기록이미지'
          className='right-0 bottom-0 absolute object-cover transition-all max-h-[50%]'
        />
        <img
          src={'/imgs/png/service/hand_3.png'}
          alt='주요기록이미지'
          className='left-0 bottom-0 absolute object-cover transition-all max-h-[50%]'
        />
      </div>
    </div>
  );
}

function MobileServiceUsage() {
  return (
    <div className='w-dvw'>
      {/* 카사요 앱 미리보기 영역 */}
      <div className='relative h-[500px] bg-[#EDF2FD]'>
        <div
          style={{
            backgroundImage: 'url("/imgs/png/service/m_cloud_1.png")',
          }}
          className='absolute bottom-0 right-0 w-full h-full bg-[#EDF2FD] bg-no-repeat bg-cover bg-bottom'
        ></div>
        <img
          src={'/imgs/png/service/m_hand_1.png'}
          alt='손_1'
          className='right-0 top-0 absolute object-cover transition-all max-h-[120px]'
        />
        <img
          src={'/imgs/png/service/m_hand_2.png'}
          alt='손_2'
          className='right-0 bottom-0 absolute object-cover transition-all max-h-[230px]'
        />
        <img
          src={'/imgs/png/service/m_hand_3.png'}
          alt='손_3'
          className='left-0 bottom-0 absolute object-cover transition-all max-h-[135px]'
        />
        <div className='absolute bottom-0 w-full flex items-center justify-center'>
          <img
            src={'/imgs/png/service/m_app_preview_1.png'}
            alt='앱 미리보기'
            className='object-cover w-[261px]'
          />
        </div>
      </div>
      {/* 서비스 바로 이용하기 */}
      <div className='bg-white p-5 pb-[50px]'>
        <button
          onClick={() => {
            window.open('https://app.carsayo.net');
          }}
          className='rounded-[10px] bg-[#222222] w-full p-[22px]'
        >
          <span className='text-white font-semibold text-[16px] leading-[16px] tracking-[-0.4px]'>
            서비스 바로 이용하기
          </span>
        </button>
      </div>
    </div>
  );
}

export default function ServiceUsage() {
  return (
    <>
      {isBrowser && <BrowserServiceUsage />}
      {isMobile && <MobileServiceUsage />}
    </>
  );
}

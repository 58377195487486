import { LinkUrl } from 'common/data/links';
import { cn } from '../../util';

export function Button_공정거래위원회({ className }: { className?: string }) {
  return (
    <button
      onClick={() => {
        window.open(LinkUrl.공정거래위원회);
      }}
      className={cn(
        'flex flex-col items-center justify-end rounded-[10px] bg-[#F8F9FA] h-[110px] p-2.5 gap-5',
        className
      )}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='128'
        height='30'
        viewBox='0 0 128 30'
        fill='none'
      >
        <g clipPath='url(#clip0_75_16330)'>
          <path
            d='M23.196 14.4678C20.5917 12.6952 17.2523 13.4182 15.5009 16.083C14.0369 18.3093 11.8288 18.5845 10.9728 18.5845C8.18698 18.5845 6.27834 16.6244 5.7127 14.5948C5.70665 14.5736 5.70363 14.5585 5.69758 14.5373C5.69153 14.5131 5.68548 14.492 5.67943 14.4678C5.45862 13.6208 5.40115 13.2185 5.40115 12.3171C5.40115 7.46534 10.38 2.06304 17.5487 2.06304C24.7175 2.06304 29.0914 7.65288 30.1742 10.7594C30.1561 10.7049 30.1379 10.6505 30.1198 10.596C28.0387 4.51918 22.2765 0.151367 15.4949 0.151367C6.95892 0.151367 0.0351562 7.07212 0.0351562 15.6111C0.0351562 23.2427 5.54029 29.7763 13.1749 29.7763C19.2638 29.7763 23.3563 26.3613 25.2347 21.6577C26.2662 19.0836 25.5342 16.0588 23.196 14.4708V14.4678Z'
            fill='#003763'
          />
          <path
            d='M30.2742 11.0617C29.3698 8.03083 25.1381 2.06592 17.5519 2.06592C10.3831 2.06592 5.4043 7.46822 5.4043 12.32C5.4043 13.2184 5.45874 13.6237 5.68258 14.4706C5.58881 14.1016 5.54041 13.7296 5.54041 13.3666C5.54041 8.31516 10.6009 4.81244 15.8005 4.81244C22.8422 4.81244 28.547 10.5202 28.547 17.559C28.547 23.0792 25.371 27.8554 20.7551 30.1482C26.7019 27.9976 30.9517 22.3019 30.9517 15.611C30.9517 14.029 30.737 12.6316 30.2711 11.0617H30.2742Z'
            fill='#E4022D'
          />
          <path
            d='M49.8972 14.6851V15.9858H38.5391V14.6851H42.9734V12.6343H44.4707V14.6851H49.9002H49.8972ZM47.4471 14.0046V11.0523H39.4526V9.72144H48.9837V14.0015H47.4471V14.0046ZM47.3926 19.3524C47.3926 19.7577 47.3079 20.1419 47.1446 20.5018C46.9782 20.8618 46.7574 21.1703 46.4792 21.4304C46.1918 21.7087 45.8591 21.9235 45.4749 22.0808C45.0908 22.2381 44.6824 22.3167 44.2469 22.3167C43.8113 22.3167 43.4029 22.2381 43.0218 22.0808C42.6377 21.9235 42.3049 21.7057 42.0176 21.4304C41.7393 21.1703 41.5185 20.8618 41.3521 20.5018C41.1858 20.1419 41.1041 19.7577 41.1041 19.3524C41.1041 18.9471 41.1888 18.5629 41.3521 18.203C41.5185 17.843 41.7393 17.5285 42.0176 17.2592C42.3049 16.981 42.6407 16.7692 43.0218 16.615C43.4029 16.4637 43.8143 16.3851 44.2469 16.3851C44.6794 16.3851 45.0908 16.4607 45.4749 16.615C45.8591 16.7662 46.1918 16.981 46.4792 17.2592C46.7574 17.5285 46.9782 17.84 47.1446 18.203C47.311 18.5629 47.3926 18.9471 47.3926 19.3524ZM45.8954 19.3373C45.8954 18.8866 45.7351 18.4964 45.4114 18.1727C45.0877 17.8491 44.7006 17.6888 44.2469 17.6888C43.7931 17.6888 43.406 17.8521 43.0823 18.1727C42.7587 18.4964 42.5983 18.8836 42.5983 19.3373C42.5983 19.791 42.7617 20.1721 43.0823 20.4867C43.406 20.8013 43.7931 20.9586 44.2469 20.9586C44.7006 20.9586 45.0877 20.8013 45.4114 20.4867C45.7351 20.1721 45.8954 19.791 45.8954 19.3373Z'
            fill='#575756'
          />
          <path
            d='M51.4632 11.137V9.82123H58.5291V11.137H55.5103L58.6561 14.9755L57.5884 15.8618L55.0385 12.6887L52.5309 16.0705L51.4238 15.1842L54.5696 11.14H51.4662L51.4632 11.137ZM61.2574 19.186C61.2574 19.6034 61.1727 19.9906 61.0003 20.3566C60.8279 20.7226 60.6011 21.0433 60.3137 21.3185C60.0263 21.5968 59.6876 21.8146 59.2944 21.9779C58.9011 22.1413 58.4837 22.2199 58.0421 22.2199C57.6005 22.2199 57.1921 22.1382 56.7959 21.9779C56.3996 21.8176 56.0578 21.5968 55.7705 21.3185C55.4831 21.0402 55.2562 20.7226 55.0838 20.3566C54.9114 19.9906 54.8267 19.6004 54.8267 19.186C54.8267 18.7716 54.9114 18.3814 55.0838 18.0154C55.2532 17.6494 55.4831 17.3288 55.7705 17.0535C56.0578 16.7753 56.3996 16.5575 56.7959 16.3972C57.1921 16.2338 57.6095 16.1552 58.0421 16.1552C58.4746 16.1552 58.9042 16.2368 59.2944 16.3972C59.6876 16.5575 60.0263 16.7783 60.3137 17.0535C60.6011 17.3318 60.8279 17.6524 61.0003 18.0154C61.1727 18.3784 61.2574 18.7716 61.2574 19.186ZM59.7481 19.1709C59.7481 18.7081 59.5787 18.3119 59.2429 17.9791C58.9042 17.6464 58.5019 17.48 58.03 17.48C57.5581 17.48 57.1709 17.6464 56.8382 17.9791C56.5055 18.3119 56.3391 18.7081 56.3391 19.1709C56.3391 19.6337 56.5055 20.042 56.8382 20.3687C57.1709 20.6954 57.5672 20.8618 58.03 20.8618C58.4928 20.8618 58.9042 20.6984 59.2429 20.3687C59.5787 20.042 59.7481 19.6428 59.7481 19.1709ZM60.7735 16.013V13.3391H58.4746V12.0082H60.7735V9.45825H62.3101V16.01H60.7735V16.013Z'
            fill='#575756'
          />
          <path
            d='M70.0781 20.1965H68.5415V11.609H63.8047V10.2237H70.0781V20.1965ZM73.0303 9.46143H74.5669V21.8177H73.0303V15.7228H71.1459V14.3525H73.0303V9.46143Z'
            fill='#575756'
          />
          <path
            d='M82.3258 20.1147H76.8418V14.4644H80.4292V11.5817H76.8418V10.2236H81.8962V15.8074H78.3088V18.7717H82.3258V20.1147ZM84.7486 9.47645V14.6035H86.2852V9.46436H87.7553V21.8207H86.2852V16.0161H84.7486V21.7511H83.3209V9.47645H84.7486Z'
            fill='#575756'
          />
          <path
            d='M89.418 18.0788V16.7207H97.6878V18.0788H94.2244V21.4182H92.715V18.0788H89.418ZM96.744 12.8127C96.744 13.2392 96.6593 13.6384 96.4869 14.0165C96.3145 14.3946 96.0786 14.7243 95.7731 14.9996C95.4676 15.287 95.1167 15.5138 94.7204 15.6772C94.3242 15.8435 93.9007 15.9252 93.447 15.9252C92.9933 15.9252 92.5698 15.8405 92.1736 15.6772C91.7773 15.5108 91.4264 15.2839 91.1209 14.9996C90.8154 14.7213 90.5765 14.3946 90.401 14.0165C90.2256 13.6384 90.1379 13.2361 90.1379 12.8127C90.1379 12.3892 90.2256 11.9869 90.401 11.6088C90.5765 11.2307 90.8154 10.898 91.1209 10.6106C91.4264 10.3233 91.7773 10.0964 92.1736 9.93305C92.5698 9.76669 92.9963 9.68502 93.447 9.68502C93.8977 9.68502 94.3242 9.76971 94.7204 9.93305C95.1167 10.0994 95.4676 10.3263 95.7731 10.6106C96.0786 10.898 96.3145 11.2307 96.4869 11.6088C96.6563 11.9869 96.744 12.3892 96.744 12.8127ZM95.277 12.8127C95.277 12.3136 95.0955 11.8901 94.7356 11.5392C94.3756 11.1883 93.9461 11.0129 93.447 11.0129C92.9479 11.0129 92.5214 11.1883 92.1675 11.5392C91.8136 11.8901 91.6321 12.3136 91.6321 12.8127C91.6321 13.3118 91.8106 13.7262 92.1675 14.0801C92.5214 14.434 92.9509 14.6124 93.447 14.6124C93.9431 14.6124 94.3756 14.434 94.7356 14.0801C95.0955 13.7231 95.277 13.3027 95.277 12.8127ZM98.6164 9.46118H100.141V21.8175H98.6164V9.46118Z'
            fill='#575756'
          />
          <path
            d='M107.19 16.5817V18.2453H105.665V16.5817H102.299V15.3082H110.541V16.5817H107.19ZM104.613 17.6615V20.2387H113.436V21.5696H103.076V17.6646H104.613V17.6615ZM109.28 12.0808C109.28 12.4589 109.198 12.8158 109.038 13.1546C108.875 13.4934 108.663 13.7898 108.394 14.0469C108.125 14.2949 107.813 14.4946 107.45 14.6428C107.09 14.791 106.706 14.8636 106.301 14.8636C105.895 14.8636 105.511 14.791 105.151 14.6428C104.791 14.4946 104.474 14.298 104.195 14.0469C103.935 13.7898 103.727 13.4903 103.563 13.1546C103.403 12.8158 103.321 12.4589 103.321 12.0808C103.321 11.7027 103.4 11.3458 103.563 11.013C103.727 10.6803 103.935 10.3869 104.195 10.1268C104.474 9.86965 104.791 9.66699 105.151 9.52482C105.511 9.38265 105.895 9.31006 106.301 9.31006C106.706 9.31006 107.09 9.38265 107.45 9.52482C107.81 9.66699 108.125 9.86965 108.394 10.1268C108.66 10.3869 108.875 10.6803 109.038 11.013C109.201 11.3458 109.28 11.7027 109.28 12.0808ZM107.825 12.0929C107.825 11.6785 107.677 11.3246 107.38 11.0342C107.084 10.7438 106.724 10.5986 106.301 10.5986C105.877 10.5986 105.514 10.7438 105.221 11.0342C104.924 11.3246 104.779 11.6785 104.779 12.0929C104.779 12.5073 104.927 12.8612 105.221 13.1516C105.517 13.4419 105.877 13.5871 106.301 13.5871C106.724 13.5871 107.084 13.4419 107.38 13.1516C107.677 12.8612 107.825 12.5073 107.825 12.0929ZM111.579 14.2284H109.818V12.967H111.579V9.4613H113.103V18.3814H111.579V14.2254V14.2284Z'
            fill='#575756'
          />
          <path
            d='M123.215 20.5865H115.111V19.2435H118.366V17.7069C118.218 17.6706 118.079 17.6253 117.952 17.5678C117.583 17.4196 117.259 17.223 116.981 16.9719C116.702 16.7118 116.488 16.4153 116.33 16.0766C116.173 15.7378 116.094 15.3808 116.094 15.0027C116.094 14.6246 116.173 14.2677 116.33 13.935C116.488 13.6023 116.702 13.3058 116.981 13.0487C117.259 12.7916 117.58 12.589 117.946 12.4468C118.312 12.3046 118.702 12.232 119.116 12.232C119.531 12.232 119.921 12.3046 120.281 12.4468C120.641 12.589 120.955 12.7916 121.221 13.0487C121.5 13.3089 121.717 13.6023 121.881 13.935C122.041 14.2677 122.123 14.6246 122.123 15.0027C122.123 15.3808 122.041 15.7378 121.881 16.0766C121.717 16.4153 121.5 16.7118 121.221 16.9719C120.955 17.2199 120.641 17.4196 120.281 17.5678C120.132 17.6313 119.981 17.6827 119.824 17.719V19.2435H123.218V20.5865H123.215ZM118.254 10.6954V9.37964H119.918V10.6954H122.924V12.0112H115.25V10.6954H118.257H118.254ZM120.623 14.9876C120.623 14.5823 120.474 14.2314 120.178 13.941C119.881 13.6507 119.521 13.5055 119.098 13.5055C118.675 13.5055 118.312 13.6507 118.018 13.941C117.722 14.2314 117.574 14.5823 117.574 14.9876C117.574 15.3929 117.722 15.7438 118.018 16.0403C118.312 16.3367 118.672 16.4819 119.098 16.4819C119.525 16.4819 119.884 16.3337 120.178 16.0403C120.474 15.7438 120.623 15.3929 120.623 14.9876ZM124.307 9.46131H125.831V21.8176H124.307V9.46131Z'
            fill='#575756'
          />
        </g>
        <defs>
          <clipPath id='clip0_75_16330'>
            <rect width='128' height='30' fill='white' />
          </clipPath>
        </defs>
      </svg>
      <div className='bg-[#E9ECEE] rounded-[6px] w-full'>
        <span className='text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.325px]'>
          인증기관
        </span>
      </div>
    </button>
  );
}
export function Button_한국소비자원({ className }: { className?: string }) {
  return (
    <button
      onClick={() => {
        window.open(LinkUrl.한국소비자원);
      }}
      className={cn(
        'flex flex-col items-center justify-end rounded-[10px] bg-[#F8F9FA] h-[110px] p-2.5 gap-5',
        className
      )}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='110'
        height='30'
        viewBox='0 0 110 30'
        fill='none'
      >
        <path
          d='M16.4997 15.5384C14.9179 15.5384 13.4439 15.063 12.2176 14.2522C12.3535 14.6316 12.4294 15.0431 12.4294 15.4705C12.4294 17.4717 10.8076 19.0974 8.8024 19.0974C6.79719 19.0974 5.17544 17.4757 5.17544 15.4705C5.17544 13.4653 6.79719 11.8475 8.8024 11.8475C9.2298 11.8475 9.64123 11.9234 10.0207 12.0592L13.0844 8.99547C11.8581 8.18061 10.3842 7.70926 8.8024 7.70926C4.51236 7.69728 1.0332 11.1764 1.0332 15.4665C1.0332 19.7565 4.51236 23.2357 8.8024 23.2357C13.0924 23.2357 16.5316 19.7925 16.5716 15.5344C16.5476 15.5344 16.5237 15.5344 16.5037 15.5344'
          fill='#FF6640'
        />
        <path
          d='M8.80228 7.69731C10.3841 7.69731 11.858 8.17265 13.0843 8.98353C12.9485 8.60405 12.8726 8.19262 12.8726 7.76522C12.8726 5.764 14.4944 4.13825 16.4996 4.13825C18.5048 4.13825 20.1265 5.76 20.1265 7.76522C20.1265 9.77043 18.5048 11.3922 16.4996 11.3922C16.0722 11.3922 15.6607 11.3163 15.2813 11.1805L12.2175 14.2442C13.4438 15.0591 14.9178 15.5304 16.4996 15.5304C20.7896 15.5304 24.2688 12.0513 24.2688 7.76122C24.2688 3.47118 20.7896 0 16.4996 0C12.2095 0 8.77033 3.43922 8.73438 7.70131C8.75435 7.70131 8.77831 7.70131 8.80228 7.70131'
          fill='#91C3E1'
        />
        <path
          d='M30.7593 9.7825V10.8251H28.143C27.8354 10.8251 27.6836 10.9569 27.6836 11.2245C27.6836 11.4921 27.8593 11.6679 28.127 11.6679H34.3663C34.6659 11.6679 34.8257 11.5081 34.8257 11.2085C34.8257 11.0168 34.7697 10.8251 34.3663 10.8251H31.7819V9.7825C31.7819 9.54283 31.5622 9.38306 31.2347 9.38306C30.9391 9.38306 30.7593 9.53085 30.7593 9.7825Z'
          fill='#565A5C'
        />
        <path
          d='M29.8092 18.3263C29.6455 18.3263 29.4258 18.3662 29.4258 18.7097C29.4258 18.8655 29.4977 19.0493 29.8412 19.0493H30.3765V21.366C30.3765 22.129 30.8119 22.2848 31.459 22.2848H37.7422C37.9819 22.2848 38.2295 22.2288 38.2295 21.8254C38.2295 21.5658 38.0658 21.4419 37.7262 21.4419H31.427V18.6937C31.427 18.4581 31.3032 18.3223 31.0875 18.3223H29.8092V18.3263Z'
          fill='#565A5C'
        />
        <path
          d='M29.6336 14.8913C29.6336 13.9965 30.2767 13.3974 31.2354 13.3974C32.1941 13.3974 32.8811 14.0325 32.8811 14.9033C32.8811 15.7741 32.1781 16.4092 31.2075 16.4092C30.3127 16.4092 29.6336 15.7541 29.6336 14.8873M28.627 14.8913C28.627 16.2414 29.7415 17.26 31.2194 17.26C32.6974 17.26 33.8877 16.2494 33.8877 14.9073C33.8877 13.5651 32.7853 12.5386 31.2634 12.5386C29.7415 12.5386 28.623 13.5292 28.623 14.8913'
          fill='#565A5C'
        />
        <path
          d='M35.6162 9.27905C35.4285 9.27905 35.2168 9.32299 35.2168 9.66252C35.2168 9.92615 35.4325 10.018 35.6322 10.018H36.4351V18.4863C36.4351 18.7938 36.6188 18.9616 36.9544 18.9616C37.2899 18.9616 37.4896 18.7938 37.4896 18.4743V14.9672H39.2432C39.5268 14.9672 39.7185 14.7874 39.7185 14.5238C39.7185 14.2601 39.5388 14.0924 39.2432 14.0924H37.4896V9.6066C37.4896 9.41087 37.3698 9.28305 37.194 9.28305H35.6202L35.6162 9.27905Z'
          fill='#565A5C'
        />
        <path
          d='M41.4685 15.0671C41.1689 15.0671 41.0371 15.187 41.0371 15.4666C41.0371 15.7462 41.1769 15.898 41.4805 15.898H46.2658V17.9272H42.8785C42.579 17.9272 42.4192 18.075 42.4192 18.3586C42.4192 18.5942 42.6109 18.774 42.8626 18.774H49.8289V22.0574C49.8289 22.377 50.0166 22.5607 50.3482 22.5607C50.6797 22.5607 50.8834 22.373 50.8834 22.0574V18.6542C50.8834 18.2188 50.7636 17.9272 50.1844 17.9272H47.3164V15.898H52.1297C52.5251 15.898 52.5611 15.6783 52.5611 15.4546C52.5611 15.191 52.4133 15.0711 52.1017 15.0711H41.4685V15.0671Z'
          fill='#565A5C'
        />
        <path
          d='M59.6783 15.874C59.4706 15.874 59.3827 15.9899 59.3827 16.2615C59.3827 16.5012 59.4825 16.617 59.6942 16.617H60.2135V19.0975H55.2205C54.9249 19.0975 54.7891 19.2254 54.7891 19.513C54.7891 19.8006 54.9369 19.9564 55.2324 19.9564H65.8816C66.2811 19.9564 66.313 19.7207 66.313 19.497C66.313 19.2254 66.1612 19.0975 65.8537 19.0975H61.2641V16.2016C61.2641 16.0218 61.1163 15.874 60.9365 15.874H59.6743H59.6783Z'
          fill='#565A5C'
        />
        <path
          d='M60.85 10.022H59.424C59.1963 10.022 58.9646 10.0699 58.9646 10.4054C58.9646 10.741 59.2122 10.8049 59.4559 10.8049H60.0511C60.0511 10.8568 60.0351 11.2523 60.0351 11.2523C59.8194 13.3613 57.3229 14.8672 55.7051 15.2228C55.5933 15.2427 55.5134 15.3026 55.4695 15.3945C55.4455 15.4464 55.4375 15.4984 55.4375 15.5543C55.4375 15.6542 55.4695 15.754 55.5094 15.8259C55.6612 16.0696 55.833 16.1295 56.1365 16.0496C57.93 15.5663 59.9153 13.9925 60.5983 12.5305C61.3253 14.0004 63.5183 15.5423 65.0601 16.0216C65.4556 16.1415 65.6074 16.0935 65.7631 15.798C65.8231 15.6581 65.8231 15.5143 65.7631 15.3985C65.7112 15.2907 65.6114 15.2108 65.4915 15.1788C63.4743 14.6436 61.3533 13.1017 61.2055 11.1963V10.4414C61.2055 10.2017 61.0697 10.0419 60.85 10.026V10.022Z'
          fill='#565A5C'
        />
        <path
          d='M42.8832 10.082C42.5836 10.082 42.4238 10.2298 42.4238 10.5134C42.4238 10.7491 42.6156 10.9289 42.8672 10.9289H49.8335V13.9127C49.8335 14.2323 50.0213 14.416 50.3528 14.416C50.6843 14.416 50.8881 14.2283 50.8881 13.9127V10.809C50.8881 10.3736 50.7682 10.082 50.189 10.082H42.8832Z'
          fill='#565A5C'
        />
        <path
          d='M73.9401 17.6434H70.1654V14.7474H73.9401V17.6434ZM68.4717 10.5812C68.28 10.5812 68.1602 10.725 68.1602 10.9527C68.1602 11.1524 68.3 11.2922 68.4997 11.2922H69.1108V17.5196C69.1108 18.1787 69.4504 18.4823 70.1813 18.4823H73.996C74.6831 18.4823 75.0066 18.1307 75.0066 17.3678V10.9647C75.0066 10.6931 74.8908 10.5772 74.6192 10.5772H73.341C73.1332 10.5772 73.0014 10.721 73.0014 10.9487C73.0014 11.1484 73.1532 11.2882 73.3729 11.2882H73.9361V13.8886H70.1614V11.0046C70.1614 10.721 70.0336 10.5732 69.7779 10.5732H68.4717V10.5812Z'
          fill='#565A5C'
        />
        <path
          d='M76.9502 9.27905C76.6866 9.27905 76.5508 9.40288 76.5508 9.65054C76.5508 9.98208 76.8663 10.006 76.9662 10.006H77.7851V21.2504C77.7851 21.6339 78.0607 21.7697 78.3203 21.7697C78.58 21.7697 78.8556 21.6299 78.8556 21.2345V9.59062C78.8556 9.41087 78.7278 9.27905 78.56 9.27905H76.9582H76.9502Z'
          fill='#565A5C'
        />
        <path
          d='M104.552 9.27914C104.368 9.27914 104.153 9.32308 104.153 9.66261C104.153 9.92624 104.368 10.0181 104.568 10.0181H105.371V18.3146H102.539C102.247 18.3146 102.107 18.4384 102.107 18.6981C102.107 18.9577 102.251 19.0975 102.539 19.0975H105.906C106.106 19.0975 106.441 19.0336 106.441 18.6062V9.5987C106.441 9.40696 106.314 9.27515 106.13 9.27515H104.556L104.552 9.27914Z'
          fill='#565A5C'
        />
        <path
          d='M98.5845 12.6065C98.5845 11.6718 99.4033 11.2524 100.214 11.2524C101.025 11.2524 101.844 11.6758 101.844 12.6065C101.844 13.5372 100.997 13.9886 100.214 13.9886C99.4313 13.9886 98.5845 13.5532 98.5845 12.6065ZM99.6829 9.84232V10.4455C98.3528 10.7291 97.5898 11.516 97.5898 12.6065C97.5898 14.1164 98.952 14.8034 100.218 14.8034C101.484 14.8034 102.847 14.1164 102.847 12.6065C102.847 11.504 102.076 10.7171 100.737 10.4455V9.84232C100.737 9.62662 100.522 9.48682 100.19 9.48682C99.7508 9.48682 99.6869 9.71051 99.6869 9.84232'
          fill='#565A5C'
        />
        <path
          d='M98.1257 19.0216C97.9619 19.0216 97.7422 19.0615 97.7422 19.405C97.7422 19.5608 97.8141 19.7446 98.1576 19.7446H98.6929V21.3663C98.6929 22.1293 99.1243 22.285 99.7754 22.285H106.426C106.67 22.285 106.913 22.2291 106.913 21.8257C106.913 21.566 106.75 21.4422 106.41 21.4422H99.7434V19.3891C99.7434 19.1534 99.6196 19.0176 99.4039 19.0176H98.1257V19.0216Z'
          fill='#565A5C'
        />
        <path
          d='M103.959 15.77H96.4411C96.1415 15.77 96.0137 15.8899 96.0137 16.1695C96.0137 16.4491 96.1535 16.6009 96.4571 16.6009H99.6886V17.8312C99.6886 18.1507 99.8763 18.3345 100.208 18.3345C100.539 18.3345 100.739 18.1467 100.739 17.8312V16.6009H103.987C104.382 16.6009 104.418 16.3812 104.418 16.1575C104.418 15.8938 104.27 15.774 103.959 15.774'
          fill='#565A5C'
        />
        <path
          d='M82.7917 10.5854C82.6878 10.5854 82.3763 10.5854 82.3763 10.941C82.3763 11.2525 82.5241 11.4163 82.8077 11.4163H85.1844V12.323C85.1844 14.8675 83.6505 17.0565 82.2285 18.0151C82.0847 18.111 81.9888 18.2548 81.9688 18.4066C81.9688 18.4266 81.9648 18.4505 81.9648 18.4705C81.9648 18.5704 81.9968 18.6662 82.0647 18.7381C82.2085 18.9458 82.4921 18.9738 82.7717 18.802C83.8782 17.9872 85.2243 16.3295 85.7116 14.7077C86.2149 16.1937 87.2415 17.5917 88.4718 18.4625C88.8193 18.7062 89.019 18.7102 89.2227 18.4745C89.2986 18.4026 89.3426 18.2668 89.3426 18.135C89.3426 18.115 89.3426 18.095 89.3426 18.075C89.3266 17.9273 89.2507 17.8074 89.1309 17.7315C87.2894 16.5771 86.2788 14.6358 86.2788 12.2631V11.4163H88.6396C89.019 11.4163 89.071 11.2006 89.071 10.9729C89.071 10.7452 88.9991 10.5894 88.6396 10.5894H82.8037L82.7917 10.5854Z'
          fill='#565A5C'
        />
        <path
          d='M90.2295 9.27905C90.0578 9.27905 89.8301 9.315 89.8301 9.65054C89.8301 9.91417 90.0458 10.006 90.2455 10.006H91.0644V21.2464C91.0644 21.6299 91.332 21.7657 91.5836 21.7657C91.8353 21.7657 92.1149 21.6259 92.1149 21.2305V15.1709H93.9883C94.2679 15.1709 94.4477 14.9951 94.4477 14.7275C94.4477 14.4599 94.2719 14.3121 93.9883 14.3121H92.1149V9.59062C92.1149 9.41087 91.9871 9.27905 91.8033 9.27905H90.2295Z'
          fill='#565A5C'
        />
      </svg>
      <div
        onClick={() => {
          window.open(LinkUrl.한국소비자원);
        }}
        className='bg-[#E9ECEE] rounded-[6px] w-full'
      >
        <span className='text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.325px]'>
          인증기관
        </span>
      </div>
    </button>
  );
}

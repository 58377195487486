import { motion } from 'framer-motion';

export default function Section03() {
  return (
    <section className='w-full pt-[136px] pb-[200px]  overflow-hidden'>
      <div className='w-full relative mb-[200px]'>
        <div className='w-[1200px] mx-auto relative z-0 flex flex-col justify-start items-start gap-[50px]'>
          <div className='flex flex-col justify-start items-start gap-6'>
            <motion.span
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='mb-1 text-[20px] leading-[20px] text-[#6E77F6] font-[600]'
            >
              간편한 서비스
            </motion.span>
            <motion.strong
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[32px] leading-[48px]'
            >
              신차 구매와 내 차 팔기,
              <br />
              자동차 금융/보험/사고수리까지 한번에
            </motion.strong>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='text-[18px] leading-[28.8px]'
            >
              자동차 관련 폭넓은 서비스를 제공하여 손품 팔던 불편함을 해소하고
              정보의 비대칭을 해결합니다.
              <br />
              신차 구매, 내 차 팔기, 자동차 금융, 다이렉트 자동차 보험, 자동차
              사고수리 등 자동차 관련 모든 서비스를 제공합니다.
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 1 },
            }}
            className='flex justify-center items-center h-[360px] gap-[14px]'
          >
            <img
              src='../imgs/png/main_section03_img01.png'
              alt='예시 이미지01'
              className='w-[270px] h-[300px] object-cover'
            />
            <img
              src='../imgs/png/main_section03_img02.png'
              alt='예시 이미지02'
              className='w-[270px] h-[300px] object-cover'
            />
          </motion.div>
          <motion.img
            initial={{ opacity: 0, x: 20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            src='../imgs/png/main_section03_mockup01.png'
            alt='목업1'
            className='absolute -right-[110px] -top-[27.7%] h-[1170px] object-contain z-[1]'
          />
        </div>
        <div className='w-full h-[360px] bg-[#F8F9FA] absolute bottom-0 left-0 -z-[10]'></div>
      </div>
      <div className='w-full relative mb-[200px]'>
        <div className='w-[1200px] mx-auto relative z-0 flex flex-col justify-start items-start gap-[50px] pl-[485px]'>
          <div className='flex flex-col justify-start items-start gap-6'>
            <motion.span
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='mb-1 text-[20px] leading-[20px] text-[#6E77F6] font-[600]'
            >
              네트워크 인프라
            </motion.span>
            <motion.strong
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[32px] leading-[48px]'
            >
              국내 최대 네트워크
              <br />
              인프라 보유 플랫폼
            </motion.strong>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='text-[18px] leading-[28.8px]'
            >
              국내 최대 카 매니저&에이전시 네트워크 인프라를 활용하여 최적의
              선택을 할 수 있도록 돕습니다.
              <br />
              허위 견적 없이 선택한 차량과 옵션에 맞춘 전국의 다양한 견적을
              실시간으로 비교할 수 있습니다.
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 1 },
            }}
          >
            <div className='flex justify-center items-center h-[360px] gap-[14px]'>
              <img
                src='../imgs/png/main_section03_img03.png'
                alt='예시 이미지03'
                className='w-[270px] h-[300px] object-cover'
              />
              <img
                src='../imgs/png/main_section03_img04.png'
                alt='예시 이미지04'
                className='w-[270px] h-[300px] object-cover'
              />
            </div>
          </motion.div>
          <motion.img
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            src='../imgs/png/main_section03_mockup02.png'
            alt='목업2'
            className='absolute -left-[25.7%] -top-[28%] h-[1170px] object-contain z-[1]'
          />
        </div>
        <div className='w-full h-[360px] bg-[#F8F9FA] absolute bottom-0 left-0 -z-[10]'></div>
      </div>
      <div className='w-full relative'>
        <div className='w-[1200px] mx-auto relative z-0 flex flex-col justify-start items-start gap-[50px]'>
          <div className='flex flex-col justify-start items-start gap-6'>
            <motion.span
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='mb-1 text-[20px] leading-[20px] text-[#6E77F6] font-[600]'
            >
              자동차 사고수리 서비스
            </motion.span>
            <motion.strong
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[32px] leading-[48px]'
            >
              어떤 상황이든 안전을 최우선으로
              <br />
              실시간 수리 과정 확인 및 출고, A/S까지
            </motion.strong>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='text-[18px] leading-[28.8px]'
            >
              자동차 사고수리에서 가장 중요한 것은 경험과 기술입니다.
              <br />
              카사요가 엄선한 1급 공업사 기준으로 입고부터 출고까지 차별화된
              서비스를 제공합니다.
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 1 },
            }}
            className='flex justify-center items-center h-[360px] gap-[14px]'
          >
            <img
              src='../imgs/png/main_section03_img05.png'
              alt='예시 이미지05'
              className='w-[270px] h-[300px] object-cover'
            />
            <img
              src='../imgs/png/main_section03_img06.png'
              alt='예시 이미지06'
              className='w-[270px] h-[300px] object-cover'
            />
          </motion.div>
          <motion.img
            initial={{ opacity: 0, x: 20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            src='../imgs/png/main_section03_mockup03.png'
            alt='목업3'
            className='absolute -right-[110px] -top-[27.7%] h-[1170px] object-contain z-[1]'
          />
        </div>
        <div className='w-full h-[360px] bg-[#F8F9FA] absolute bottom-0 left-0 -z-[10]'></div>
      </div>
    </section>
  );
}

import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { cn } from '../../../util/index';
import { Pagination, Autoplay } from 'swiper/modules';
import { slideContents } from '../main';

export default function MSection06() {
  const [page, setPage] = useState(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  return (
    <section className='w-[100dvw] pt-[80px] pb-[50px]'>
      <div className='w-full flex flex-col justify-start items-start gap-[20px]'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-full px-[20px] text-[20px] leading-[30px] font-[600]'
        >
          많은 분들이
          <br />
          좋은 조건으로 거래했어요
        </motion.div>
        <div className='w-full'>
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='reviewSwiper'
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.realIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {slideContents.map((review, index) => (
              <SwiperSlide
                key={`reviewSwiper_slide_${index}`}
                className='!h-auto'
              >
                <div className='w-full h-full flex justify-center items-center py-[50px]'>
                  <div className='w-[calc(100%-40px)] min-h-[calc(100%-40px)] h-full flex flex-col justify-start items-start p-[20px] pt-[40px] pb-[50px] shadow-[0px_20px_40px_#DBE2EF] rounded-[20px]'>
                    <div className='rating-box flex justify-start items-center gap-1 mb-[12px]'>
                      {Array.from(
                        { length: review.rating ?? 0 },
                        (_, index) => (
                          <Star
                            key={`score_star_${index}`}
                            width={'30'}
                            height={'30'}
                            fill={'#FED890'}
                            stroke='transparent'
                          />
                        )
                      )}
                    </div>
                    <div className='review-top flex flex-col justify-start items-start gap-[2px] mb-[20px]'>
                      <strong className='text-[20px] leading-[30px] font-[600] tracking-normal break-keep'>
                        {review.title}
                      </strong>
                      <span className='text-[16px] leading-[24px] text-[#868686] tracking-normal'>
                        [{review.type}] {review.nickName} 님
                      </span>
                    </div>
                    <p className='text-[#868686] text-[16px] leading-[24px] tracking-normal break-keep'>
                      {review.reviewText}
                    </p>
                  </div>
                  <div className='profile-box absolute -top-[10px] right-[20px] w-[120px] h-[120px] rounded-full overflow-hidden'>
                    <img
                      src={review.profile}
                      alt='프로필 이미지'
                      className='w-full h-full object-fill'
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='flex justify-center items-center gap-[20px] -mt-[25px]'>
            <div
              style={{
                cursor: page === 1 ? 'default' : 'pointer',
              }}
              onClick={() => swiper?.slidePrev()}
              className={cn(
                'flex justify-center items-center w-[40px] h-[40px] bg-[#fff] border-[#E5EAF4] border-[2px] rounded-full z-[5]'
              )}
            >
              <ChevronLeft
                className='-ml-[2px]'
                width={20}
                height={20}
                strokeWidth={2}
                stroke={'#1A1F27'}
              />
            </div>
            <ul className='flex justify-center items-center gap-2.5'>
              {slideContents.map((_, index) => (
                <li
                  className={cn(
                    'w-2 h-2 rounded-full bg-[#D5DDEC] transition-all duration-200',
                    index + 1 === page && 'bg-[#1A1F27]'
                  )}
                ></li>
              ))}
            </ul>
            <div
              style={{
                cursor: page === slideContents.length ? 'default' : 'pointer',
              }}
              onClick={() => swiper?.slideNext()}
              className={cn(
                'flex justify-center items-center w-[40px] h-[40px] bg-[#fff] border-[#E5EAF4] border-[2px] rounded-full z-[5]'
              )}
            >
              <ChevronRight
                className='-mr-[2px]'
                width={20}
                height={20}
                strokeWidth={2}
                stroke={'#1A1F27'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const LinkUrl = {
  한국소비자원: 'https://www.kca.go.kr/',
  공정거래위원회: 'https://www.ftc.go.kr/',
  가족친화지원사업: 'https://www.ffsb.kr/',
  소비자중심경영소개: 'https://www.kca.go.kr/ccm/certSystemOutlineView.do',
  지식재산경영인증: 'https://www.ripc.org/ipcert/introduce',
  벤처확인기업: 'https://www.smes.go.kr/venturein/institution/ventureGuide',

  앱: {
    플레이스토어:
      'https://play.google.com/store/apps/details?id=com.ksapp.carsayo',
    앱스토어:
      'https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990',
  },
};

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { superCarList } from '../main';

export default function Section04() {
  return (
    <section className='w-full bg-[#222222] pt-[100px]'>
      <div className='w-full relative'>
        <div className='w-[1240px] mx-auto flex justify-start items-center text-[32px] leading-[32px] font-[300] text-white relative z-[1]'>
          <span className='inline-flex justify-center items-center px-[20px] bg-[#222]'>
            SUPER CAR WITH{' '}
            <strong className='font-[600] pl-2 inline-block'>CARSAYO</strong>
          </span>
        </div>
        <span className='w-full h-[1px] bg-[#FFFFFF] absolute left-0 top-0 bottom-0 my-auto z-0'></span>
      </div>
      <div className='w-full pt-[66px] pb-[120px] min-w-[1200px]'>
        <Swiper
          loop={true}
          speed={3000}
          slidesPerView={'auto'}
          autoplay={{
            delay: 0,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
          spaceBetween={20}
          centeredSlides={true}
          className='py-[66px]'
        >
          {superCarList.map((item, index) => (
            <SwiperSlide
              key={`superCar_Slide_${item.title}_${index}`}
              className='!w-[450px]'
            >
              <div
                key={`suparCar_${item.title}_${index}`}
                className='w-full aspect-[450/300] z-0 relative overflow-hidden'
              >
                <img
                  src={item.imgUrl}
                  alt={`${item.title}이미지`}
                  className='w-full h-full object-fill'
                />
                <span className='text-[24px] leading-[34px] text-white font-[600] absolute bottom-[30px] left-[32px] z-[1]'>
                  {item.title}
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

import { superCarList } from '../main';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

export default function MSection04() {
  return (
    <section className='w-[100dvw] bg-[#222222] pt-[45px] overflow-hidden'>
      <div className='w-full relative pl-[20px] mb-[30px] text-white z-0'>
        <span className='bg-[#222] pr-[10px] text-[20px] leading-[25px] font-[300]'>
          SUPER CAR WITH <strong className='font-[600]'>CARSAYO</strong>
        </span>
        <span className='absolute w-full top-0 bottom-0 my-auto left-[20px] h-[1px] bg-white -z-[10]'></span>
      </div>
      <div className='w-full overflow-x-auto pb-[50px]'>
        <Swiper
          loop={true}
          speed={3000}
          slidesPerView={'auto'}
          autoplay={{
            delay: 0,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
          spaceBetween={10}
          centeredSlides={true}
        >
          {superCarList.map((item, index) => (
            <SwiperSlide
              key={`superCar_Slide_${item.title}_${index}`}
              className='!w-[210px]'
            >
              <div
                key={`suparCar_${item.title}_${index}`}
                className='w-full aspect-[210/260] z-0 relative overflow-hidden'
              >
                <img
                  src={item.imgUrl}
                  alt={`${item.title}이미지`}
                  className='w-full h-full object-cover'
                />
                <span className='text-[14px] leading-[14px] text-white font-[600] absolute bottom-[24px] left-[20px] z-[1]'>
                  {item.title}
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

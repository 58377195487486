import { motion } from 'framer-motion';
import { BrowserView, MobileView } from 'react-device-detect';

// pc
const Section01 = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 1 },
      }}
      style={{
        backgroundImage: 'url(/imgs/png/esg_section01_bg.png)',
      }}
      className='w-[1200px] overflow-hidden h-[460px] bg-no-repeat bg-cover bg-bottom flex justify-between items-stertch'
    >
      <div className='w-full h-full flex flex-col justify-start items-start py-[80px] pl-[80px] gap-6 text-white'>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[40px] leading-[60px] font-[700]'
        >
          카사요(주)의 ESG 방향성
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.5 },
          }}
          className='text-[18px] leading-[28.8px]'
        >
          자동차 쇼핑 통합 플랫폼과 서비스를 포함한 모든 경영활동은
          <br />
          고객과 더불어, 더 나아가 사회와 환경의 지속 가능성을 실천해 나가며{' '}
          <br />
          모범적인 기업이 되고자 노력하고 있습니다.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.25, duration: 0.5 },
          }}
        >
          <div className='flex justify-start items-start gap-[6px] pt-[12px ]'>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Environmental
            </span>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Social
            </span>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Governance{' '}
            </span>
          </div>
        </motion.div>
      </div>
      <div className='w-[426px] h-[428px] mt-auto mr-[133px] shrink-0'>
        <img
          src='../imgs/png/esg_section01_img01.png'
          alt='esg경영'
          className='w-full object-contain'
        />
      </div>
    </motion.section>
  );
};
const Section02 = () => {
  return (
    <section className='w-[1200px] mx-auto flex flex-col justify-start items-start gap-[50px] pt-[100px]'>
      <div className='flex flex-col justify-start items-start gap-4'>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] mb-[10px]'
        >
          ESG 경영
        </motion.strong>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='flex flex-col justify-start items-start gap-6 text-[18px] leading-[28.8px]'
        >
          긍정적 환경영향을 극대화하고 포용적이고 더불어 사회를 수용하는 것을
          <br />
          두 가지 전략적 방향으로 삼고 구체적인 목표를 설정하여 이를 달성하고자
          노력하고 있습니다.
          <br />
          카사요(주)가 제공하는 플랫폼과 서비스를 포함한 모든 경영활동을 통해
          우리를 둘러싼 모든 고객,
          <br />더 나아가 사회와 환경의 지속가능성을 실현해 나갈 계획입니다.
        </motion.p>
      </div>
      <div className='flex justify-start items-start gap-[40px]'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[600px] h-[400px]'
        >
          <img
            src='../imgs/png/esg_section02_img01.png'
            alt='환경안전경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='py-[40px] flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-4 text-[20px] leading-[20px] font-[600] text-[#0075FF]'
          >
            Environmental
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[32px] leading-[48px] font-[700] inline-block mb-6'
          >
            환경안전 경영
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px] font-[400] mb-6'
          >
            환경경영시스템(ISO14001)을 취득하였으며 환경 및 안전 법규를 준수하여
            <br />
            사고 예방 활동에 전 구성원이 적극 참여하고 있습니다.
          </motion.p>
          <div className='text-[16px] leading-[16px] flex justify-center items-center gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              기후 변화 및 탄소 배출
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              환경 오염 및 환경 규제
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              생태계 및 생물 다양성
            </motion.span>
          </div>
        </div>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='grid grid-cols-3 gap-[10px] -mt-[10px] mb-[50px]'
      >
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg01.png'
            alt='환경안전경영1'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Carbon Neutrality
          </span>
        </li>
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg02.png'
            alt='환경안전경영2'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Circularity
          </span>
        </li>
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg03.png'
            alt='환경안전경영3'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Clean Technology
          </span>
        </li>
      </motion.ul>
      <div className='flex justify-start items-start gap-[40px]'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[600px] h-[400px]'
        >
          <img
            src='../imgs/png/esg_section02_img02.png'
            alt='사회책임경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='py-[40px] flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-4 text-[20px] leading-[20px] font-[600] text-[#0075FF]'
          >
            Social
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[32px] leading-[48px] font-[700] inline-block mb-6'
          >
            사회책임 경영
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px] font-[400] mb-6'
          >
            지역사회와의 상생을 도모하며 공정한 거래와 윤리적 경영을 통해
            <br />
            사회적 책임을 다하고 있습니다.
          </motion.p>
          <div className='text-[16px] leading-[16px] flex justify-center items-center gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              기후 변화 및 탄소 배출
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              지역사회 관계
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              인권, 성별 평등 및 다양성
            </motion.span>
          </div>
        </div>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='grid grid-cols-3 gap-[10px] -mt-[10px] mb-[50px]'
      >
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg04.png'
            alt='사회책임경영1'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Decent Workplace
          </span>
        </li>
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg05.png'
            alt='사회책임경영2'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Diversity & Inclusion
          </span>
        </li>
        <li className='relative w-full aspect-[392/150]'>
          <img
            src='../imgs/png/esg_section02_listImg06.png'
            alt='사회책임경영3'
            className='w-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            Design for All
          </span>
        </li>
      </motion.ul>
      <div className='flex justify-start items-start gap-[40px]'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[600px] h-[400px]'
        >
          <img
            src='../imgs/png/esg_section02_img03.png'
            alt='지배구조경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='py-[40px] flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-4 text-[20px] leading-[20px] font-[600] text-[#0075FF]'
          >
            Governance
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[32px] leading-[48px] font-[700] inline-block mb-6'
          >
            지배구조 경영
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px] font-[400] mb-6'
          >
            신뢰를 바탕으로 한 투명한 의사결정을 통해
            <br />
            지속 가능한 성장을 추구합니다.
          </motion.p>
          <div className='text-[16px] leading-[16px] flex justify-center items-center gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              이사회 및 감사위원회 구성
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              뇌물 및 반부패
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              기업윤리
            </motion.span>
          </div>
        </div>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='w-full -mt-[10px]'
      >
        <li className='relative w-full h-[150px]'>
          <img
            src='../imgs/png/pc_esg_section02_listImg07.png'
            alt='지배구조경영1'
            className='w-full h-full object-cover'
          />
          <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
            신뢰받는 경영
          </span>
        </li>
      </motion.ul>
    </section>
  );
};

// 모바일
const MSection01 = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 1 },
      }}
      style={{
        backgroundImage: 'url(/imgs/png/m_esg_section01_bg.png)',
      }}
      className='w-full overflow-hidden relative aspect-[375/484] min-h-[484px] max-h-[520px] bg-no-repeat bg-cover bg-bottom'
    >
      <div className='w-full h-full flex flex-col justify-start items-start py-[50px] px-[20px] gap-[14px] text-white relative z-[1]'>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-full text-[24px] leading-[36px] font-[700] break-keep'
        >
          카사요(주)의 ESG 방향성
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.5 },
          }}
          className='w-full text-[16px] leading-[25.6px] break-keep max-w-[325px]'
        >
          자동차 쇼핑 통합 플랫폼과 서비스를
          <br />
          포함한 모든 경영활동은 고객과 더불어, <br />더 나아가 사회와 환경의
          지속 가능성을 실천해 나가며 모범적인 기업이 되고자 노력하고 있습니다.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.25, duration: 0.5 },
          }}
        >
          <div className='flex text-[15px] leading-[15px] justify-start items-start gap-[6px] pt-[12px]'>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Environmental
            </span>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Social
            </span>
            <span className='inline-flex bg-[#22222233] p-[10px] rounded-[6px]'>
              Governance{' '}
            </span>
          </div>
        </motion.div>
      </div>
      <div className='absolute w-[250px] h-[277px] mt-auto shrink-0 -bottom-[45px] -right-[25px]'>
        <img
          src='../imgs/png/esg_section01_img01.png'
          alt='esg경영'
          className='absolute w-full object-contain z-0'
        />
      </div>
    </motion.section>
  );
};
const MSection02 = () => {
  return (
    <section className='w-full flex flex-col justify-start items-start gap-[50px] py-[50px] px-[20px]'>
      <div className='flex flex-col justify-start items-start gap-4'>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[20px] leading-[30px]'
        >
          ESG 경영
        </motion.strong>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='flex flex-col justify-start items-start gap-6 text-[16px] leading-[25.6px]'
        >
          <span>
            긍정적 환경영향을 극대화하고 포용적이고 더불어 사회를 수용하는 것을
            두 가지 전략적 방향으로 삼고 구체적인 목표를 설정하여 이를
            달성하고자 노력하고 있습니다.
          </span>
          <span>
            카사요(주)가 제공하는 플랫폼과 서비스를 포함한 모든 경영활동을 통해
            우리를 둘러싼 모든 고객, 더 나아가 사회와 환경의 지속가능성을 실현해
            나갈 계획입니다.
          </span>
        </motion.p>
      </div>
      <div className='flex flex-col justify-start items-start gap-4'>
        <div className='flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-[6px] text-[14px] leading-[14px] font-[600] text-[#0075FF]'
          >
            Environmental
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[20px] leading-[30px] font-[600] inline-block'
          >
            환경안전 경영
          </motion.strong>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-full aspect-[335/223]'
        >
          <img
            src='../imgs/png/esg_section02_img01.png'
            alt='환경안전경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start'>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[16px] leading-[25.6px] font-[400] mb-4 break-keep'
          >
            환경경영시스템(ISO14001)을 취득하였으며 환경 및 안전 법규를 준수하여
            사고 예방 활동에 전 구성원이 적극 참여하고 있습니다.
          </motion.p>
          <div className='text-[15px] leading-[15px] flex flex-wrap justify-start items-start gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              기후 변화 및 탄소 배출
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              환경 오염 및 환경 규제
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              생태계 및 생물 다양성
            </motion.span>
          </div>
        </div>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex flex-col justify-start items-start gap-[10px] mt-[16px]'
        >
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg01.png'
              alt='환경안전경영1'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Carbon Neutrality
            </span>
          </li>
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg02.png'
              alt='환경안전경영2'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Circularity
            </span>
          </li>
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg03.png'
              alt='환경안전경영3'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Clean Technology
            </span>
          </li>
        </motion.ul>
      </div>
      <div className='flex flex-col justify-start items-start gap-4'>
        <div className='flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-[6px] text-[14px] leading-[14px] font-[600] text-[#0075FF]'
          >
            Social
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[20px] leading-[30px] font-[600] inline-block'
          >
            사회책임 경영
          </motion.strong>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-full aspect-[335/223]'
        >
          <img
            src='../imgs/png/esg_section02_img02.png'
            alt='사회책임경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start'>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[16px] leading-[25.6px] font-[400] mb-4 break-keep'
          >
            지역사회와의 상생을 도모하며 공정한 거래와 윤리적 경영을 통해 사회적
            책임을 다하고 있습니다.
          </motion.p>
          <div className='text-[15px] leading-[15px] flex flex-wrap justify-start items-start gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              데이터 보호 및 프라이버시
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              지역사회 관계
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              인권, 성별 평등 및 다양성
            </motion.span>
          </div>
        </div>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex flex-col justify-start items-start gap-[10px] mt-[16px]'
        >
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg04.png'
              alt='사회책임경영1'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Decent Workplace
            </span>
          </li>
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg05.png'
              alt='사회책임경영2'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Diversity & Inclusion
            </span>
          </li>
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg06.png'
              alt='사회책임경영3'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              Design for All
            </span>
          </li>
        </motion.ul>
      </div>
      <div className='flex flex-col justify-start items-start gap-4'>
        <div className='flex flex-col justify-start items-start'>
          <motion.span
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='inline-block mb-[6px] text-[14px] leading-[14px] font-[600] text-[#0075FF]'
          >
            Governance
          </motion.span>
          <motion.strong
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[20px] leading-[30px] font-[600] inline-block'
          >
            지배구조 경영
          </motion.strong>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-full aspect-[335/223]'
        >
          <img
            src='../imgs/png/esg_section02_img03.png'
            alt='지배구조경영'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start'>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[16px] leading-[25.6px] font-[400] mb-4 break-keep'
          >
            신뢰를 바탕으로 한 투명한 의사결정을 통해 지속 가능한 성장을
            추구합니다.
          </motion.p>
          <div className='text-[15px] leading-[15px] flex flex-wrap justify-start items-start gap-2'>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              이사회 및 감사위원회 구성
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.15, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              뇌물 및 반부패
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
              className='inline-block py-[12px] px-[10px] rounded-[6px] bg-[#F8F9FA]'
            >
              기업윤리
            </motion.span>
          </div>
        </div>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex flex-col justify-start items-start gap-[10px] mt-[16px]'
        >
          <li className='relative w-full aspect-[335/130]'>
            <img
              src='../imgs/png/esg_section02_listImg07.png'
              alt='지배구조경영1'
              className='w-full object-cover'
            />
            <span className='absolute text-[18px] leading-[28.8px] font-[500] left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] text-white'>
              신뢰받는 경영
            </span>
          </li>
        </motion.ul>
      </div>
    </section>
  );
};

export default function Esg() {
  return (
    <>
      <BrowserView className='flex flex-col items-center justify-center pt-[40px] pb-[100px]'>
        <Section01 />
        <Section02 />
      </BrowserView>
      <MobileView>
        <MSection01 />
        <MSection02 />
      </MobileView>
    </>
  );
}

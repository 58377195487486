import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/promotion/service'))
      navigate('/service', { replace: true });
  }, [location.pathname]);

  return <div className='flex-grow'>NotFound</div>;
}

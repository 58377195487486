export default function AppleIcon({ fill }: { fill?: string }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.6996 12.1184C17.6758 9.45134 19.8571 8.17084 19.9534 8.11149C18.7277 6.29936 16.8166 6.04679 16.1387 6.01649C14.5115 5.85106 12.9631 6.98506 12.1401 6.98506C11.3171 6.98506 10.0414 6.04427 8.69558 6.07079C6.91954 6.09478 5.28858 7.11261 4.3743 8.71007C2.53322 11.9353 3.90152 16.7163 5.69882 19.3316C6.57434 20.6121 7.6187 22.0504 8.99075 21.9986C10.3153 21.9469 10.8156 21.1361 12.414 21.1361C14.0124 21.1361 14.4615 21.9986 15.861 21.9772C17.2831 21.9469 18.1849 20.6664 19.0529 19.3859C20.0585 17.8996 20.4762 16.4612 20.5 16.3854C20.4687 16.3741 17.7259 15.3108 17.6984 12.1184H17.6996Z'
        fill={fill || 'white'}
      />
      <path
        d='M15.1893 4.45597C16.0524 3.54139 16.6332 2.26745 16.4736 1C15.2337 1.04151 13.7307 1.72778 12.8425 2.63847C12.0429 3.45187 11.3409 4.75175 11.5316 5.99196C12.9134 6.08407 14.3307 5.37315 15.1893 4.45597Z'
        fill={fill || 'white'}
      />
    </svg>
  );
}

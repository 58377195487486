import Section01 from './components/Section01';
import Section02 from './components/Section02';
import Section03 from './components/Section03';
import Section04 from './components/Section04';
import Section05 from './components/Section05';
import Section06 from './components/Section06';
import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView,
} from 'react-device-detect';
import MSection01 from './m/Section01';
import MSection02 from './m/Section02';
import MSection03 from './m/Section03';
import MSection04 from './m/Section04';
import MSection05 from './m/Section05';
import MSection06 from './m/Section06';
import MainPopupBrowser from 'components/popup/MainPopupBrowser';
import MainPopupMobile from 'components/popup/MainPopupMobile';

const CarsayoMain = () => {
  return (
    <>
      <BrowserView className='min-w-[1260px] flex flex-auto flex-col justify-center items-center py-[40px]'>
        <Section01 />
        <Section02 />
        <Section03 />
        <Section04 />
        <Section05 />
        <Section06 />
      </BrowserView>
      <MobileView>
        <MSection01 />
        <MSection02 />
        <MSection03 />
        <MSection04 />
        <MSection05 />
        <MSection06 />
      </MobileView>
      {/* {isBrowser && <MainPopupBrowser mode='canCloseOneDay' />}
      {isMobile && <MainPopupMobile />} */}
    </>
  );
};
export default CarsayoMain;

import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { cn } from '../../../util/index';
import { Pagination, Autoplay } from 'swiper/modules';
import { slideContents } from '../main';

const Section06 = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  /** 두배 뻥튀기해서 사용 */
  const doubledSlideContents = useMemo(() => {
    return slideContents.concat(slideContents);
  }, []);

  return (
    <section className='w-full pt-[40px] overflow-hidden'>
      <div className='w-full flex flex-col justify-start items-start gap-[50px]'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-[1200px] mx-auto text-[32px] leading-[48px] font-[700]'
        >
          많은 분들이 카사요를 통해
          <br />
          좋은 조건으로 구매했어요
        </motion.div>
        <div className='w-full mx-auto relative flex justify-center items-center'>
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            loop={true}
            speed={1500}
            slidesPerView='auto'
            className='reviewSwiper'
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {doubledSlideContents.map((review, index) => (
              <SwiperSlide
                key={`reviewSwiper_slide_${index}`}
                className='!w-[640px] !h-auto'
              >
                <div
                  className={cn(
                    'reviewSlide h-full pt-[60x] px-[20px] w-[620px]'
                  )}
                >
                  <div className='reviewSlide-box w-auto h-full pt-[70px]'>
                    <div className='profile-box absolute -top-[0px] left-0 right-0 mx-auto w-[160px] h-[160px] rounded-full overflow-hidden'>
                      <img
                        src={review.profile}
                        alt='프로필 이미지'
                        className='w-full h-full object-fill'
                      />
                    </div>
                    <div className='reviewSlide-content px-[40px] pb-[53px] pt-[101px] mb-[60px] shadow-[0px_20px_40px_#DBE2EF] rounded-[20px]'>
                      <div className='rating-box flex justify-start items-center gap-1 mb-[12px]'>
                        {Array.from(
                          { length: review.rating ?? 0 },
                          (_, index) => (
                            <Star
                              key={`score_star_${index}`}
                              width={'30'}
                              height={'30'}
                              fill={'#FFC93E'}
                              stroke='transparent'
                            />
                          )
                        )}
                      </div>
                      <div className='review-top flex flex-col justify-start items-start gap-[2px] mb-[28px]'>
                        <strong className='text-[26px] leading-[39px] font-[600] tracking-normal'>
                          {review.title}
                        </strong>
                        <span className='text-[18px] leading-[27px] text-[#868686] tracking-normal'>
                          [{review.type}] {review.nickName} 님
                        </span>
                      </div>
                      <p className='text-[#868686] text-[18px] leading-[27px] tracking-normal whitespace-pre-line'>
                        {review.reviewText}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            style={{
              cursor: page === 1 ? 'default' : 'pointer',
            }}
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[calc(50%-328px)] top-[44%] flex justify-center items-center w-[54px] h-[54px] bg-[#fff] border-[#E5EAF4] border-[2px] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={28}
              height={28}
              strokeWidth={2}
              stroke={page === 1 ? '#D5DCEB' : '#1A1F27'}
            />
          </div>
          <div
            style={{
              cursor:
                page === doubledSlideContents.length ? 'default' : 'pointer',
            }}
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[calc(50%-308px)] top-[44%] flex justify-center items-center w-[54px] h-[54px] bg-[#fff] border-[#E5EAF4] border-[2px] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={28}
              height={28}
              strokeWidth={2}
              stroke={
                page === doubledSlideContents.length ? '#D5DCEB' : '#1A1F27'
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Section06;

import { motion } from 'framer-motion';

export default function Section05() {
  return (
    <section className='w-[1200px] mx-auto pt-[100px] pb-[60px] flex flex-col justify-start items-start gap-[40px]'>
      <div className='w-full flex justify-start items-center gap-[58px]'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-[375px] h-[196px] rounded-[16px]'
        >
          <img
            src='../imgs/png/main_section05_item01.png'
            alt='내차시세부터판매까지'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start gap-[20px]'>
          <motion.strong
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='text-[24px] leading-[36px] font-[700]'
          >
            내 차 시세부터 판매까지
            <br />
            감가 없는 내 차 팔기
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px]'
          >
            정확하고 투명한 정보를 바탕으로 믿을 수 있는 내 차 팔기 서비스를
            제공합니다.
            <br />
            국토교통부 인가 단체인 자동차매매사업조합연합회 소속 카 매니저님들의
            견적을 받아볼 수 있습니다.
          </motion.p>
        </div>
      </div>
      <div className='w-full flex justify-start items-center gap-[58px]'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='w-[375px] h-[196px] rounded-[16px]'
        >
          <img
            src='../imgs/png/main_section05_item02.png'
            alt='내상황에가장적합한'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start gap-[20px]'>
          <motion.strong
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[24px] leading-[36px] font-[700]'
          >
            내 상황에 가장 적합한
            <br />
            다이렉트 자동차 보험 비교
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px]'
          >
            가장 알맞은 다이렉트 자동차 보험을 찾아 합리적인 선택할 수 있는
            서비스를 제공합니다.
            <br />
            운전 습관과 상황에 맞춰 국내 모든 다이렉트 자동차 보험을 한 번에
            조회 및 비교할 수 있습니다.
          </motion.p>
        </div>
      </div>
      <div className='w-full flex justify-start items-center gap-[58px]'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.15, duration: 0.5 },
          }}
          className='w-[375px] h-[196px] rounded-[16px]'
        >
          <img
            src='../imgs/png/main_section05_item03.png'
            alt='더저렴하고안전한'
            className='w-full h-full object-fill'
          />
        </motion.div>
        <div className='flex flex-col justify-start items-start gap-[20px]'>
          <motion.strong
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.15, duration: 0.5 },
            }}
            className='text-[24px] leading-[36px] font-[700]'
          >
            더 저렴하고 안전한
            <br />
            자동차 금융 견적 제공
          </motion.strong>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
            className='text-[18px] leading-[28.8px]'
          >
            카사요는 깨끗한 자동차 거래 시장을 만들기 위해 장기 렌트 및 리스
            금융 견적을 직접 제안드립니다.
            <br />
            [금융상품판매 중개업자 등록증]을 보유한 플랫폼으로 더 저렴하고
            안전한 견적을 제공이 가능합니다.
          </motion.p>
        </div>
      </div>
    </section>
  );
}

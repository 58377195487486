import { useEffect, useRef, useState } from 'react';
import { cn } from 'util/index';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { Pagination, Autoplay } from 'swiper/modules';
import { BrowserView, MobileView } from 'react-device-detect';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// pc
const Section01 = () => {
  const [isVideoPlay, setVideoPlay] = useState<boolean | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();
  const handleClickEvents = {
    videoPlay: () => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setVideoPlay(true);
        } else {
          videoRef.current.pause();
          setVideoPlay(false);
        }
      }
    },
  };

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section
      className={cn(
        'w-[1200px] h-[675px] mx-auto transition-opacity duration-300',
        layoutLoad ? 'opacity-100' : 'opacity-50'
      )}
    >
      <div
        onClick={handleClickEvents.videoPlay}
        className='w-full relative h-full border border-[#EEEEEE] group cursor-pointer select-none'
      >
        {/* Thumbnale */}
        <img
          src='../imgs/png/video_bg_2.png'
          alt='재생 이미지'
          className={cn(
            'w-full absolute z-[1]',
            isVideoPlay !== null && 'hidden'
          )}
        />
        {isVideoPlay !== null && (
          <div
            className={cn(
              'absolute w-full h-full flex justify-center items-center z-[1]',
              isVideoPlay === true ? 'bg-transparent' : 'bg-[#0000001A]'
            )}
          >
            {isVideoPlay === true ? (
              <span className='w-[100px] h-[100px] opacity-0 transition-all flex justify-center items-center group-hover:opacity-100'>
                <img
                  src='../imgs/icons/stop.png'
                  alt='일시정지 이미지'
                  className='w-full object-contain'
                />
              </span>
            ) : isVideoPlay === null ? (
              <span className='w-[100px] h-[100px] flex justify-center items-center'>
                <img
                  src='../imgs/icons/play.png'
                  alt='재생 이미지'
                  className='w-full object-contain'
                />
              </span>
            ) : (
              <span className='w-[100px] h-[100px] flex justify-center items-center'>
                <img
                  src='../imgs/icons/play.png'
                  alt='재생 이미지'
                  className='w-full object-contain'
                />
              </span>
            )}
          </div>
        )}
        <div className='w-full h-full overflow-hidden flex items-center justify-center'>
          <video
            className='w-[calc(100%+8px)] h-[calc(100%+8px)] -m-[4px] object-cover cursor-pointer'
            webkit-playsinline='true'
            playsInline
            preload='auto'
            loop={true}
            ref={videoRef}
          >
            <source src='../videos/carsayo.mp4' type='video/mp4' />
          </video>
        </div>
      </div>
    </section>
  );
};
const Section02 = () => {
  return (
    <section className='w-[1200px]  mx-auto pt-[100px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start'>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700] mb-6'
        >
          자동차 쇼핑 통합 플랫폼, 카사요
        </motion.strong>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[18px] leading-[28.8px] font-[400] text-[#111]'
        >
          자동차와 쇼핑이 조화를 이루어 다양한 자동차 관련 서비스를 제공하는
          자동차 쇼핑 통합 플랫폼을 의미하며
          <br />
          부드러운 곡선 구조의 심플한 이니셜 구성을 통해 친근함과 유연성을
          강조하였으며 고객 친화적인 기업의 의지를 담았습니다.
        </motion.p>
      </div>
      <ul className='w-full flex flex-col justify-start items-start gap-[40px]'>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-full relative border border-[#EEEEEE] flex justify-center items-center h-[400px]'
        >
          <span className='absolute top-[30px] left-[30px] bg-[#F8F9FA] py-[14px] px-4 rounded-[10px] text-[18px] leading-[18px] font-[500]'>
            Symbol
          </span>
          <img
            src='../imgs/png/company/ci_section02_item01.png'
            alt='심볼이미지'
            className='w-[268px] object-contain'
          />
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='w-full relative border border-[#EEEEEE] flex justify-center items-center h-[400px]'
        >
          <span className='absolute top-[30px] left-[30px] bg-[#F8F9FA] py-[14px] px-4 rounded-[10px] text-[18px] leading-[18px] font-[500]'>
            Wordmark
          </span>
          <img
            src='../imgs/png/company/ci_section02_item02.png'
            alt='워드마크이미지'
            className='w-[452px] object-contain'
          />
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='w-full border border-[#EEEEEE] flex justify-center items-center h-[400px] divide-x-[1px]'
        >
          <div className='w-[50%] relative h-full flex justify-center items-center'>
            <span className='absolute top-[30px] left-[30px] bg-[#F8F9FA] py-[14px] px-4 rounded-[10px] text-[18px] leading-[18px] font-[500]'>
              Horizontal
            </span>
            <img
              src='../imgs/png/company/ci_section02_item03.png'
              alt='로고가로이미지'
              className='w-[336px] object-contain'
            />
          </div>
          <div className='w-[50%] relative h-full flex justify-center items-center'>
            <span className='absolute top-[30px] left-[30px] bg-[#F8F9FA] py-[14px] px-4 rounded-[10px] text-[18px] leading-[18px] font-[500]'>
              Vertical
            </span>
            <img
              src='../imgs/png/company/ci_section02_item04.png'
              alt='로고세로이미지'
              className='w-[251px] object-contain'
            />
          </div>
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-full relative border border-[#EEEEEE] flex justify-center items-center h-[400px] overflow-hidden bg-[#7373FF]'
        >
          <img
            src='../imgs/png/company/carsayo_splash.gif'
            alt='스플래시'
            className='w-[490px] object-cover'
          />
        </motion.li>
      </ul>
    </section>
  );
};
const Section03 = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  return (
    <section className='w-[1200px]  mx-auto pt-[100px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start'>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700] mb-6'
        >
          2020년, 어디셔널 로고
        </motion.strong>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[18px] leading-[28.8px] font-[400] text-[#111]'
        >
          자동차 생활의 바탕이 되어 사람들의 삶에 더 나은 행복을 함께 만들어
          나아가겠다는
          <br />
          의미를 담아 자동차 모양과 상호를 간결하게 조합했습니다.
        </motion.p>
      </div>
      <ul className='w-full flex flex-col justify-start items-start gap-[40px]'>
        <li className='w-full border border-[#EEEEEE] flex justify-center items-center h-[400px] divide-x-[1px]'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            className='w-[50%] relative h-full flex justify-center items-center'
          >
            <img
              src='../imgs/png/company/ci_section03_item01.png'
              alt='로고가로이미지'
              className='w-[250px] object-contain'
            />
          </motion.div>
          <div className='w-[50%] relative h-full overflow-hidden pt-[89px]'>
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              loop={true}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(swiper.realIndex + 1);
              }}
              speed={1000}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              spaceBetween={'30px'}
              className='!h-[330px] -my-[50px]'
            >
              <SwiperSlide className='flex justify-center items-center'>
                <div className='w-full h-[222px] flex justify-center items-center'>
                  <video
                    style={{
                      width: '100%',
                      objectFit: 'fill',
                      marginTop: '80px',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                  >
                    <source
                      src='../videos/Carsayo_splash_1st.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='flex justify-center items-center'>
                <div className='w-full h-[222px] flex justify-center items-center'>
                  <video
                    style={{
                      width: '100%',
                      objectFit: 'fill',
                      marginTop: '80px',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                  >
                    <source
                      src='../videos/Carsayo_splash_2nd.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='flex justify-center items-center  mt-[-60px]'>
                <div className='w-full h-[300px] flex justify-center items-center'>
                  <video
                    style={{
                      width: '90%',
                      objectFit: 'fill',
                      marginTop: '0px',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                  >
                    <source
                      src='../videos/Carsayo_splash_3rd.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='flex justify-center items-center'>
                <div className='w-full h-[270px] flex justify-center items-center overflow-hidden'>
                  <video
                    style={{
                      width: '60%',
                      objectFit: 'fill',
                      marginTop: '20px',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                  >
                    <source
                      src='../videos/Carsayo_splash_4th.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='pagination mt-[35px] w-[412px] mx-auto'>
              <ul>
                {[0, 1, 2, 3].map((index) => (
                  <li
                    key={index}
                    className={cn(index + 1 === page ? 'active' : '')}
                  >
                    <div
                      className={cn(
                        'progress-bar left-0 top-0',
                        index + 1 === page && 'bg-[#222]'
                      )}
                    ></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

// 모바일
const MSection01 = () => {
  const location = useLocation();
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const [isVideoPlay, setVideoPlay] = useState<boolean | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleClickEvents = {
    videoPlay: () => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setVideoPlay(true);
        } else {
          videoRef.current.pause();
          setVideoPlay(false);
        }
      }
    },
  };
  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section className={cn('w-full pt-[50px]')}>
      <div
        className={cn(
          'w-full mb-[30px] px-[20px] flex flex-col justify-start items-start gap-4 transition-opacity duration-300',
          layoutLoad ? 'opacity-100' : 'opacity-20'
        )}
      >
        <strong className='text-[20px] leading-[30px] font-[600]'>
          자동차 쇼핑 통합 플랫폼, 카사요
        </strong>
        <p className='text-[16px] leading-[25.6px] font-[400] break-keep'>
          자동차와 쇼핑이 조화를 이루어 다양한 자동차 관련 서비스를 제공하는
          자동차 쇼핑 통합 플랫폼을 의미하며 부드러운 곡선 구조의 심플한 이니셜
          구성을 통해 친근함과 유연성을 강조하였으며 고객 친화적인 기업의 의지를
          담았습니다.
        </p>
      </div>
      <div
        onClick={handleClickEvents.videoPlay}
        className='w-full relative aspect-[375/211] group'
      >
        {/* Thumbnale */}
        <img
          src='../imgs/png/video_bg_2.png'
          alt='재생 이미지'
          className={cn(
            'w-full absolute z-[1]',
            isVideoPlay !== null && 'hidden'
          )}
        />
        <div
          className={cn(
            'absolute w-full h-full flex justify-center items-center z-[1]',
            isVideoPlay === true ? 'bg-transparent' : 'bg-[#0000001A]'
          )}
        >
          {isVideoPlay === true ? (
            <span className='w-[50px] h-[50px] opacity-0 transition-all flex justify-center items-center group-hover:opacity-100'>
              <img
                src='../imgs/icons/stop.png'
                alt='일시정지 이미지'
                className='w-full object-contain'
              />
            </span>
          ) : isVideoPlay === null ? (
            <span className='w-[50px] h-[50px] flex justify-center items-center'>
              <img
                src='../imgs/icons/play.png'
                alt='재생 이미지'
                className='w-full object-contain'
              />
            </span>
          ) : (
            <span className='w-[50px] h-[50px] flex justify-center items-center'>
              <img
                src='../imgs/icons/play.png'
                alt='재생 이미지'
                className='w-full object-contain'
              />
            </span>
          )}
        </div>
        <div className='w-full h-full overflow-hidden flex items-center justify-center'>
          <video
            className='w-[calc(100%+8px)] h-[calc(100%+8px)] -m-[4px] object-cover cursor-pointer'
            webkit-playsinline='true'
            playsInline
            preload='auto'
            loop={true}
            ref={videoRef}
          >
            <source src='../videos/carsayo.mp4' type='video/mp4' />
          </video>
        </div>
      </div>
    </section>
  );
};
const MSection02 = () => {
  return (
    <section className='w-full pt-[50px]'>
      <ul className='w-full flex flex-col justify-start items-center gap-[20px]'>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[calc(100%-40px)] relative border border-[#EEEEEE] flex justify-center items-center h-[400px]'
        >
          <span className='absolute top-[10px] left-[10px] bg-[#F8F9FA] p-[10px] rounded-[6px] text-[14px] leading-[14px] font-[500]'>
            Symbol
          </span>
          <img
            src='../imgs/png/company/ci_section02_item01.png'
            alt='심볼이미지'
            className='w-[calc(100%-100px)] object-contain'
          />
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[calc(100%-40px)] relative border border-[#EEEEEE] flex justify-center items-center h-[400px]'
        >
          <span className='absolute top-[10px] left-[10px] bg-[#F8F9FA] p-[10px] rounded-[6px] text-[14px] leading-[14px] font-[500]'>
            Wordmark
          </span>
          <img
            src='../imgs/png/company/ci_section02_item02.png'
            alt='워드마크이미지'
            className='w-[calc(100%-64px)] object-contain'
          />
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='w-[calc(100%-40px)] relative  border border-[#EEEEEE] flex justify-center items-center h-[400px] divide-x-[1px]'
        >
          <span className='absolute top-[10px] left-[10px] bg-[#F8F9FA] p-[10px] rounded-[6px] text-[14px] leading-[14px] font-[500]'>
            Horizontal
          </span>
          <img
            src='../imgs/png/company/ci_section02_item03.png'
            alt='로고가로이미지'
            className='w-[calc(100%-64px)] object-contain !border-l-0'
          />
        </motion.li>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='w-[calc(100%-40px)] relative  border border-[#EEEEEE] flex justify-center items-center h-[400px] divide-x-[1px]'
        >
          <span className='absolute top-[10px] left-[10px] bg-[#F8F9FA] p-[10px] rounded-[6px] text-[14px] leading-[14px] font-[500]'>
            Vertical
          </span>
          <img
            src='../imgs/png/company/ci_section02_item04.png'
            alt='로고세로이미지'
            className='w-[calc(100%-125px)] object-contain !border-l-0'
          />
        </motion.li>
        <li className='w-full relative flex justify-center items-center aspect-[376/516] overflow-hidden bg-[#7373FF]'>
          <video
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              border: 'none',
              outline: 'none',
            }}
            webkit-playsinline='true'
            playsInline
            loop
            autoPlay
            muted
            preload='auto'
            controls={false}
          >
            <source src='../videos/carsayo_splash.mp4' type='video/mp4' />
          </video>
        </li>
      </ul>
    </section>
  );
};
const MSection03 = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  return (
    <section className='w-full pt-[50px] px-[20px] pb-[50px]'>
      <div className='mb-[30px] flex flex-col justify-start items-start'>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[20px] leading-[30px] font-[600] mb-4'
        >
          2020년, 어디셔널 로고
        </motion.strong>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[16px] leading-[25.6px] font-[400] text-[#222] max-w-[260px] break-keep'
        >
          자동차 생활의 바탕이 되어 사람들의 삶에 더 나은 행복을 함께 만들어
          나아가겠다는 의미를 담아 자동차 모양과 상호를 간결하게 조합했습니다.
        </motion.p>
      </div>
      <ul className='w-full flex flex-col justify-start items-start gap-[20px]'>
        <motion.li
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='w-full border border-[#EEEEEE] flex justify-center items-center aspect-[335/300]'
        >
          <div className='w-full relative h-full flex justify-center items-center'>
            <img
              src='../imgs/png/company/ci_section03_item01.png'
              alt='로고가로이미지'
              className='w-[calc(100%-130px)] object-contain'
            />
          </div>
        </motion.li>
        <li className='w-full border border-[#EEEEEE] flex justify-center items-center aspect-[335/230] pb-[23px]'>
          <div className='w-full relative h-full overflow-hidden'>
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              slidesPerGroup={1}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              loop={true}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(swiper.realIndex + 1);
              }}
              speed={1000}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              spaceBetween={'30px'}
              className='aspect-[335/240] w-full overflow-hidden'
            >
              <SwiperSlide className='w-full h-full flex justify-center items-center'>
                <div className='w-full h-full flex justify-center items-center overflow-hidden'>
                  <video
                    style={{
                      height: '40%',
                      objectFit: 'cover',
                      marginTop: '40px',
                      border: 'none',
                      outline: 'none',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                    controls={false}
                  >
                    <source
                      src='../videos/Carsayo_splash_1st.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='w-full h-full flex justify-center items-center'>
                <div className='w-full h-full flex justify-center items-center overflow-hidden'>
                  <video
                    style={{
                      height: '40%',
                      objectFit: 'cover',
                      marginTop: '40px',
                      border: 'none',
                      outline: 'none',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                    controls={false}
                  >
                    <source
                      src='../videos/Carsayo_splash_2nd.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='w-full h-full flex justify-center items-center'>
                <div className='w-full h-full flex justify-center items-center overflow-hidden'>
                  <video
                    style={{
                      width: '100%',
                      objectFit: 'fill',
                      marginTop: '0px',
                      border: 'none',
                      outline: 'none',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                    controls={false}
                  >
                    <source
                      src='../videos/Carsayo_splash_3rd.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
              <SwiperSlide className='w-full h-full flex justify-center items-center'>
                <div className='w-full h-full flex justify-center items-center overflow-hidden'>
                  <video
                    style={{
                      width: '85%',
                      objectFit: 'fill',
                      marginTop: '20px',
                      border: 'none',
                      outline: 'none',
                    }}
                    webkit-playsinline='true'
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload='auto'
                    controls={false}
                  >
                    <source
                      src='../videos/Carsayo_splash_4th.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='pagination mobile mt-[33px] w-[calc(100%-80px)] mx-auto'>
              <ul className='w-full'>
                {[0, 1, 2, 3].map((index) => (
                  <li
                    key={index}
                    className={cn(index + 1 === page ? 'active' : '')}
                  >
                    <div
                      className={cn(
                        'progress-bar left-0 top-0',
                        index + 1 === page && 'bg-[#222]'
                      )}
                    ></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default function CIBI() {
  return (
    <>
      <BrowserView className='py-[40px]'>
        <Section01 />
        <Section02 />
        <Section03 />
      </BrowserView>
      <MobileView className='w-dvw'>
        <MSection01 />
        <MSection02 />
        <MSection03 />
      </MobileView>
    </>
  );
}

import CarsayoWrap from 'pages/layout/wrap';
import CarsayoMain from '../pages/main';
import NotFoundPage from '../pages/notFound';
import { createBrowserRouter } from 'react-router-dom';
import CarsayoCompany from 'pages/company';
import ServiceUsage from 'pages/service';
import MediaStory from 'pages/media';
import CCMPage from 'pages/ccm';
import Ceo from 'pages/company/components/Ceo';
import Character from 'pages/company/components/Character';
import CIBI from 'pages/company/components/CIBI';
import Overview from 'pages/company/components/Overview';
import PremiumPage from 'pages/premium';
import Philosophy from 'pages/company/components/Philosophy';
import Esg from 'pages/esg';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <CarsayoWrap />,
    children: [
      { path: '/', element: <CarsayoMain /> },
      {
        path: '/company',
        element: <CarsayoCompany />,
        children: [
          { path: 'ceo', element: <Ceo /> },
          { path: 'overview', element: <Overview /> },
          { path: 'philosophy', element: <Philosophy /> },
          { path: 'ci', element: <CIBI /> },
          { path: 'character', element: <Character /> },
        ],
      },
      {
        path: '/service',
        element: <ServiceUsage />,
      },
      {
        path: '/ccm',
        element: <CCMPage />,
      },
      {
        path: '/premium',
        element: <PremiumPage />,
      },
      {
        path: '/media',
        element: <MediaStory />,
      },
      {
        path: '/esg',
        element: <Esg />,
      },
      {
        path: '/*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

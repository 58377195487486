import { type ClassValue, clsx } from 'clsx';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';
import { twMerge } from 'tailwind-merge';

dayjs.extend(relativeTime);
dayjs.locale('ko');

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function fromNow(date: string | undefined) {
  if (!date) return '';

  const now = dayjs();
  const inputDate = dayjs(date);
  const diffInDays = now.diff(inputDate, 'day');

  if (diffInDays < 1) {
    return inputDate.fromNow();
  } else {
    return inputDate.format('YYYY.MM.DD');
  }
}

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/dialog';
import {
  MDialog,
  MDialogContent,
  MDialogHeader,
  MDialogTitle,
} from 'components/mDialog';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { cn } from 'util/index';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { Pagination, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const PageChevronRight = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <g clipPath='url(#clip0_85_3972)'>
        <path
          d='M9 6L15 12L9 18'
          stroke='white'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_85_3972'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

// pc
const Section01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section
      style={{
        backgroundImage: 'url(../imgs/png/company/character_section01_bg.png',
      }}
      className='w-full min-w-[1260px] mt-[-1px] bg-no-repeat bg-cover bg-center min-h-[700px] flex flex-col justify-start items-start'
    >
      <div className='relative w-full min-w-[1260px] flex flex-1 items-end justify-center overflow-hidden px-[30px]'>
        <img
          src='../imgs/png/company/character_section01_item01.png'
          alt='카사요히어로즈 이미지'
          className={cn(
            'h-[441px] w-[1445px] object-contain z-[1] transition-opacity duration-500',
            layoutLoad ? 'opacity-100' : 'opacity-50'
          )}
        />
        <div className='w-[130%] aspect-[2873/346] absolute top-[300px] left-1/2 -translate-x-1/2 z-0 rounded-full'>
          <img
            src='../imgs/png/company/character_section01_bg02.png'
            alt='원 배경'
            className='w-full h-full object-fill'
          />
        </div>
      </div>
      <div className='w-full flex flex-col justify-center items-center gap-[20px] bg-white pt-[50px] pb-[45px] -mt-[1px]'>
        <strong
          className={cn(
            'text-[30px] leading-[45px] font-[700] text-[#3336BD] font-[Montserrat] transition-transform duration-500',
            layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]'
          )}
        >
          CAR HEROES
        </strong>
        <p
          className={cn(
            'text-[18px] leading-[28.8px] text-center transition-transform duration-500 delay-[50ms]',
            layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]'
          )}
        >
          카사요를 대한민국을 넘어 세계 최고의 카 쇼핑 플랫폼으로
          <br />
          성장시키기 위한 그들의 노력은 오늘도 계속된다.
        </p>
      </div>
    </section>
  );
};
const Section02 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  return (
    <section className='w-[1200px] mx-auto pt-[100px]'>
      <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#44A375] to-[#337053] min-h-[600px] flex justify-center items-stretch shadow-[0px_32.72px_65.43px_#1111110D]'>
        {/* left */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-[50%] relative pt-[80px] pl-[80px] text-white'
        >
          <span className='inline-block text-[20px] leading-[20px] font-[700] mb-[72px]'>
            #1 HERO
          </span>
          <div className='flex justify-start items-center gap-4 mb-[40px]'>
            <strong className='text-[64px] leading-[64px] font-[700]'>
              카키
            </strong>
            <span className='text-[20px] leading-[20px] font-[600] bg-[#1D5D3E] rounded-[100px] py-[10px] px-[12px]'>
              딜러/경영자
            </span>
          </div>
          <p className='text-[18px] leading-[28.8px] font-[400]'>
            차를 사는 사람의 마음을 가장 잘 이해하는 딜러이자 경영자!
            <br />
            고객이 원하는 최적의 조건을 신속하고 정확하게 제시하며
            <br />
            소비자중심경영, 품질경영, 환경경영 등 고객에게 <br />
            신뢰를 안겨주기 위한 철저한 준비로 늘 분주해요.
          </p>
          <span className='absolute w-[639px] h-auto bottom-[40px] left-0'>
            <img
              src='../imgs/png/company/character_carkey_bg01.png'
              alt=''
              className='w-full object-contain'
            />
          </span>
        </motion.div>
        {/* right */}
        <div className='relative w-[50%] flex justify-center items-center'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carkey_img.png'
            alt='카키 이미지'
            className='w-[472px] object-contain z-[1] pt-[47px]'
          />
          <div className='absolute w-[150%] h-[130%] rounded-[100%] z-0 -bottom-[98%] left-[-160px] right-0 mx-auto  bg-gradient-to-b from-[#5DA783] to-[#1F7249]'></div>
          <div className='absolute w-[240px] h-[60px] rounded-[100%] bg-[#45926D] z-0 bottom-[90px] left-0 right-[15px] mx-auto'></div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          className='absolute right-0 top-[34px] inline-flex justify-between !cursor-pointer items-center py-[22px] px-[20px] w-[208px] h-[60px] rounded-l-[10px] bg-[#222] z-[1]'
        >
          <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
            카키의 다양한 모습들
          </span>
          <ChevronRight
            width={24}
            height={24}
            color='#fff'
            strokeWidth={1.2}
            className='!cursor-pointer'
          />
        </button>
      </div>
      <div
        style={{
          backgroundImage:
            'url(../imgs/png/company/character_section02_bg.png)',
        }}
        className='p-[80px] bg-cover bg-center bg-no-repeat w-full h-[450px] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-y border-[#EEEEEE]'
      >
        <span className='text-[20px] leading-[20px] font-[700]'>
          TURN AROUND
        </span>
        <div className='w-full px-[17px]'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carkey_imgs.png'
            alt='카키 이미지들'
            className='w-full object-contain mt-[-1px]'
          />
        </div>
      </div>
    </section>
  );
};
const Section03 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  return (
    <section className='w-[1200px] mx-auto pt-[100px]'>
      <div className='w-full relative overflow-hidden bg-gradient-to-b from-[#8B755E] to-[#605041] min-h-[600px] flex justify-center items-stretch shadow-[0px_32.72px_65.43px_#1111110D]'>
        {/* left */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-[50%] relative pt-[80px] pl-[80px] text-white'
        >
          <span className='inline-block text-[20px] leading-[20px] font-[700] mb-[72px]'>
            #2 HERO
          </span>
          <div className='flex justify-start items-center gap-4 mb-[40px]'>
            <strong className='text-[64px] leading-[64px] font-[700]'>
              카공이
            </strong>
            <span className='text-[20px] leading-[20px] font-[600] bg-[#463A2F] rounded-[100px] py-[10px] px-[12px]'>
              정비사
            </span>
          </div>
          <p className='text-[18px] leading-[28.8px] font-[400]'>
            눈 뜨면 잠들 때까지 차와 함께하는 차 박사!
            <br />
            자동차 수리 견적만큼은 고객님이 손해 보지 않도록,
            <br />
            같은 불편이 두 번 다시 생기지 않도록
            <br />
            꼼꼼히 정비하는 카사요의 1급 자동차 정비사!
          </p>
          <span className='absolute w-[639px] h-auto bottom-[40px] left-0'>
            <img
              src='../imgs/png/company/character_carkey_bg01.png'
              alt=''
              className='w-full object-contain'
            />
          </span>
        </motion.div>
        {/* right */}
        <div className='relative w-[60%] flex justify-center items-start'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carGong_img.png'
            alt='카공이 이미지'
            className='w-[420px] object-contain z-[1] pt-[71px] mr-[20px]'
          />
          <div className='absolute w-[140%] h-[130%] rounded-[100%] z-0 -bottom-[98.5%] left-[-130px] right-0 mx-auto  bg-gradient-to-b from-[#907E6D] to-[#75624F]'></div>
          <div className='absolute w-[334px] h-[60px] rounded-[100%] bg-[#83705C] z-0 bottom-[90px] left-0 right-0 mx-auto'></div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          className='absolute right-0 top-[34px] !cursor-pointer inline-flex justify-between items-center py-[22px] px-[20px] w-[208px] h-[60px] rounded-l-[10px] bg-[#222] z-[1]'
        >
          <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
            카공이의 다양한 모습들
          </span>
          <ChevronRight
            width={24}
            height={24}
            color='#fff'
            strokeWidth={1.2}
            className='!cursor-pointer'
          />
        </button>
      </div>
      <div
        style={{
          backgroundImage:
            'url(../imgs/png/company/character_section02_bg.png)',
        }}
        className='py-[80px] px-[67px] bg-cover bg-center bg-no-repeat w-full h-[450px] overflow-hidden flex flex-col justify-start items-start gap-[90px] border-y border-[#EEEEEE]'
      >
        <span className='text-[20px] leading-[20px] font-[700] pl-[13px]'>
          TURN AROUND
        </span>
        <div className='w-full'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carGong_imgs.png'
            alt='카공이 이미지들'
            className='w-full object-contain mt-[-20px]'
          />
        </div>
      </div>
    </section>
  );
};
const Section04 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  return (
    <section className='w-[1200px] mx-auto pt-[100px]'>
      <div className='w-full relative overflow-hidden bg-gradient-to-b from-[#6F8FEC] to-[#375ECD] min-h-[600px] flex justify-center items-stretch shadow-[0px_32.72px_65.43px_#1111110D]'>
        {/* left */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='w-[50%] relative pt-[80px] pl-[80px] text-white'
        >
          <span className='inline-block text-[20px] leading-[20px] font-[700] mb-[72px]'>
            #3 HERO
          </span>
          <div className='flex justify-start items-center gap-4 mb-[40px]'>
            <strong className='text-[64px] leading-[64px] font-[700]'>
              카집사
            </strong>
            <span className='text-[20px] leading-[20px] font-[600] bg-[#3156C6] rounded-[100px] py-[10px] px-[12px]'>
              고객 상담사
            </span>
          </div>
          <p className='text-[18px] leading-[28.8px] font-[400] whitespace-nowrap'>
            고객님의 표정과 음성만 듣고도 무엇을 원하는지 캐치하는 능력의
            <br />
            소유자. 고객의 질문에 친절히 답하고 그들이 힘들어할 때 곁에서 공감해
            <br />
            주는 카사요의 진정한 고객 지킴이에요. 고객을 최우선으로 생각하는
            <br />
            마인드만큼은 세계 최고!
          </p>
          <span className='absolute w-[639px] h-auto bottom-[40px] left-0'>
            <img
              src='../imgs/png/company/character_carkey_bg01.png'
              alt=''
              className='w-full object-contain'
            />
          </span>
        </motion.div>
        {/* right */}
        <div className='relative w-[65%] flex justify-center items-start'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carJipsa_img.png'
            alt='집사 이미지'
            className='w-[205px] object-contain z-[1] pt-[75px] mr-[30px]'
          />
          <div className='absolute w-[130%] h-[130%] rounded-[100%] z-0 -bottom-[97%] left-[-110px] right-0 mx-auto  bg-gradient-to-b from-[#658BF9] to-[#5074DB]'></div>
          <div className='absolute w-[200px] h-[60px] rounded-[100%] bg-[#567DEB] z-0 bottom-[100px] left-0 right-[25px] mx-auto'></div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          className='absolute right-0 top-[34px] !cursor-pointer inline-flex justify-between items-center py-[22px] px-[20px] w-[208px] h-[60px] rounded-l-[10px] bg-[#222] z-[1]'
        >
          <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
            카집사의 다양한 모습들
          </span>
          <ChevronRight
            width={24}
            height={24}
            color='#fff'
            strokeWidth={1.2}
            className='!cursor-pointer'
          />
        </button>
      </div>
      <div
        style={{
          backgroundImage:
            'url(../imgs/png/company/character_section02_bg.png)',
        }}
        className='p-[80px] bg-cover bg-center bg-no-repeat w-full h-[450px] overflow-hidden flex flex-col justify-start items-start gap-[90px] border-y border-[#EEEEEE]'
      >
        <span className='text-[20px] leading-[20px] font-[700]'>
          TURN AROUND
        </span>
        <div className='w-full px-[30px]'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carJipsa_imgs.png'
            alt='카집사 이미지들'
            className='w-full object-contain mt-[-38px]'
          />
        </div>
      </div>
    </section>
  );
};
const CarkeyPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='w-[1000px] bg-white'>
        <DialogHeader>
          <DialogTitle className='text-center font-[700] text-[28px] leading-[28px]'>
            딜러이자 경영자, 카키의 다양한 모습이에요.
          </DialogTitle>
        </DialogHeader>
        <div className='p-[40px]'>
          <ul className='grid grid-cols-3 gap-[20px]'>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey01.png'
                alt='카키이미지1'
                className='w-[92px] object-contain'
              />
            </li>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey02.png'
                alt='카키이미지2'
                className='w-[76px] object-contain'
              />
            </li>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey03.png'
                alt='카키이미지3'
                className='w-[110px] object-contain'
              />
            </li>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey04.png'
                alt='카키이미지4'
                className='w-[81px] object-contain'
              />
            </li>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey05.png'
                alt='카키이미지5'
                className='w-[133px] object-contain'
              />
            </li>
            <li className='flex justify-center items-center bg-[#EAF6F0] p-[43px]'>
              <img
                src='../imgs/png/company/character/carkey06.png'
                alt='카키이미지6'
                className='w-[127px] object-contain'
              />
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const CarGongPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='w-[1000px] bg-white'>
        <DialogHeader>
          <DialogTitle className='text-center font-[700] text-[28px] leading-[28px]'>
            정비사, 카공이의 다양한 모습이에요.
          </DialogTitle>
        </DialogHeader>
        <div className='p-[40px]'>
          <ul className='grid grid-cols-3 gap-[20px]'>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px] pb-[26px]'>
              <img
                src='../imgs/png/company/character/carGong01.png'
                alt='카공이이미지1'
                className='w-[148px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px] pb-[26px]'>
              <img
                src='../imgs/png/company/character/carGong02.png'
                alt='카공이이미지2'
                className='w-[155px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px]  pb-[26px]'>
              <img
                src='../imgs/png/company/character/carGong03.png'
                alt='카공이이미지3'
                className='w-[128px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px] pb-[24px]'>
              <img
                src='../imgs/png/company/character/carGong04.png'
                alt='카공이이미지4'
                className='w-[116px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px]  pb-[24px]'>
              <img
                src='../imgs/png/company/character/carGong05.png'
                alt='카공이이미지5'
                className='w-[158px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#F2EDE9] p-[43px] h-[204px] pb-[23px]'>
              <img
                src='../imgs/png/company/character/carGong06.png'
                alt='카공이이미지6'
                className='w-[138px] object-contain'
              />
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const CarJipsaPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='w-[1000px] bg-white'>
        <DialogHeader>
          <DialogTitle className='text-center font-[700] text-[28px] leading-[28px]'>
            고객 상담사, 카집사의 다양한 모습이에요.
          </DialogTitle>
        </DialogHeader>
        <div className='p-[40px]'>
          <ul className='grid grid-cols-3 gap-[20px]'>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[29px]'>
              <img
                src='../imgs/png/company/character/carjipsa01.png'
                alt='카집사이미지1'
                className='w-[102px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[29px]'>
              <img
                src='../imgs/png/company/character/carjipsa02.png'
                alt='카집사이미지2'
                className='w-[132px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[28px]'>
              <img
                src='../imgs/png/company/character/carjipsa03.png'
                alt='카집사이미지3'
                className='w-[85px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[18px]'>
              <img
                src='../imgs/png/company/character/carjipsa04.png'
                alt='카집사이미지4'
                className='w-[148px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[17px]'>
              <img
                src='../imgs/png/company/character/carjipsa05.png'
                alt='카집사이미지5'
                className='w-[106px] object-contain'
              />
            </li>
            <li className='flex justify-center items-end bg-[#EEF2FF] p-[30px] h-[204px] pb-[17px]'>
              <img
                src='../imgs/png/company/character/carjipsa06.png'
                alt='카집사이미지6'
                className='w-[93px] object-contain'
              />
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// 모바일
const MSection01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section className='w-full bg-[#E5E6FF] flex flex-col justify-start items-start'>
      <div className='relative w-full min-h-[448px] aspect-[580/448] tiny:min-h-[400px] flex justify-center items-start overflow-hidden bg-white pb-[50px] mt-[30px]'>
        <div className='w-full h-[50px] bg-[#E5E6FF]'></div>
        <img
          src='../imgs/png/company/m_character_section01_bg.png'
          alt='카사요히어로즈 이미지'
          className={cn(
            'h-full w-full object-cover absolute left-0 right-0 mx-auto bottom-[30px] z-[1] transition-opacity duration-500',
            layoutLoad ? 'opacity-100' : 'opacity-20'
          )}
        />
      </div>
      <div className='w-full pt-[5px] px-[20px] flex flex-col justify-center items-center gap-[20px] bg-white pb-[50px] mt-[-2px]'>
        <strong
          className={cn(
            'text-[24px] leading-[36px] font-[700] text-[#3336BD] font-[Montserrat] transition-transform duration-500',
            layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]'
          )}
        >
          CAR HEROES
        </strong>
        <p
          className={cn(
            'text-[16px] leading-[25.6px] text-center max-w-[290px] break-keep transition-transform duration-500 delay-[50ms]',
            layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]'
          )}
        >
          카사요를 대한민국을 넘어
          <br />
          세계 최고의 카 쇼핑 플랫폼으로 성장시키기 위한 그들의 노력은 오늘도
          계속된다.
        </p>
      </div>
    </section>
  );
};
const MSection02 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carkey_turn01.png',
    '../imgs/png/company/character/carkey_turn02.png',
    '../imgs/png/company/character/carkey_turn03.png',
    '../imgs/png/company/character/carkey_turn04.png',
    '../imgs/png/company/character/carkey_turn05.png',
  ];
  return (
    <section className='w-full px-[20px] mb-[50px]'>
      <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#44A375] to-[#337053] px-[20px] pt-[33px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32.72px_65.43px_#1111110D] z-[1]'>
        {/* top */}
        <div className='w-full relative text-white'>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='flex justify-between items-center mb-[19px]'
          >
            <div className='flex justify-start items-center gap-2'>
              <strong className='text-[24px] leading-[24px] font-[700]'>
                카키
              </strong>
              <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#1D5D3E] rounded-[100px] py-[10px] px-[12px]'>
                딜러/경영자
              </span>
            </div>
            <span className='inline-block text-[14px] leading-[14px] font-[700]'>
              #1 HERO
            </span>
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[15px] leading-[24px] font-[400] break-keep'
          >
            차를 사는 사람의 마음을 가장 잘 이해하는 딜러이자 경영자! 고객이
            원하는 최적의 조건을 신속하고 정확하게 제시하며 소비자중심경영,
            품질경영, 환경경영 등 고객에게 신뢰를 안겨주기 위한 철저한 준비로 늘
            분주해요.
          </motion.p>
        </div>
        {/* bottom */}
        <div className='relative w-full flex flex-col justify-between items-center'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carkey_img.png'
            alt='카키 이미지'
            className='w-[calc(100%-35px)] max-w-[260px] object-contain z-[1] pt-[40px] pb-[44px]'
          />
          <button
            onClick={() => setIsOpen(true)}
            className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
          >
            <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
              카키의 다양한 모습들
            </span>
          </button>
          <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#5DA783] to-[#1F7249]'></div>
          <div className='absolute w-[132px] h-[38px] rounded-[100%] bg-[#45926D] z-0 bottom-[90px] left-0 right-[15px] mx-auto'></div>
        </div>
      </div>
      <div className='relative'>
        <div className='check-gradient-background w-full h-[calc((100vw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-l border-[#F4F4F4] relative shadow-inner-custom'>
          <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
            TURN AROUND
          </span>
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full  h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
            spaceBetween={10}
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <img
                  src={character}
                  alt={`카키_이미지`}
                  className={cn(
                    'h-[38%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*4.1)]'
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src='../imgs/png/company/m_character_section02_bg02.png'
            alt=''
            className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
          />
        </div>
        {/* <div
          style={{
            background: `
                linear-gradient(to bottom, transparent 98% , #F4F4F4 20%) 0 0 / 100% 30px repeat-y,
                linear-gradient(to right, transparent 29px, #F4F4F4 29px) 0 0 / 30px 100% repeat-x
                  white`,
          }}
          className='py-[30px] px-[20px] w-full h-[456px] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border border-[#F4F4F4] relative'
        >
          <span className='text-[16px] leading-[16px] font-[700] z-[1]'>
            TURN AROUND
          </span>
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full'
            spaceBetween={10}
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <img
                  src={character}
                  alt={`카키_이미지`}
                  className={cn(
                    'h-[50%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[7%]'
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src='../imgs/png/company/m_character_section02_bg02.png'
            alt=''
            className='w-full h-[2px] absolute left-0 bottom-[34%] z-0'
          />
          <div
            style={{
              backgroundImage:
                'url(../imgs/png/company/character/gradient-bg.png)',
            }}
            className='absolute left-0 top-0 w-full h-full z-0 bg-cover bg-no-repeat bg-center'
          ></div>
        </div> */}
        <div
          onClick={() => swiper?.slidePrev()}
          className={cn(
            'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronLeft
            className='-ml-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
        <div
          onClick={() => swiper?.slideNext()}
          className={cn(
            'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronRight
            className='-mr-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
      </div>
    </section>
  );
};
const MSection03 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carGong_turn01.png',
    '../imgs/png/company/character/carGong_turn02.png',
    '../imgs/png/company/character/carGong_turn03.png',
    '../imgs/png/company/character/carGong_turn04.png',
    '../imgs/png/company/character/carGong_turn05.png',
  ];
  return (
    <section className='w-full px-[20px] mb-[50px]'>
      <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#8B755E] to-[#605041] px-[20px] pt-[33px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32.72px_65.43px_#1111110D]'>
        {/* top */}
        <div className='w-full relative text-white'>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='flex justify-between items-center mb-[19px]'
          >
            <div className='flex justify-start items-center gap-2'>
              <strong className='text-[24px] leading-[24px] font-[700]'>
                카공이
              </strong>
              <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#463A2F] rounded-[100px] py-[10px] px-[12px]'>
                정비사
              </span>
            </div>
            <span className='inline-block text-[14px] leading-[14px] font-[700]'>
              #2 HERO
            </span>
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[15px] leading-[24px] font-[400] break-keep'
          >
            눈 뜨면 잠들 때까지 차와 함께하는 차 박사! 자동차 수리 견적만큼은
            고객님이 손해 보지 않도록, 같은 불편이 두 번 다시 생기지 않도록
            꼼꼼히 정비하는 카사요의 1급 자동차 정비사!
          </motion.p>
        </div>
        {/* bottom */}
        <div className='relative w-full flex flex-col justify-between items-center'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carGong_img.png'
            alt='카공이 이미지'
            className='w-[calc(100%-35px)] max-w-[240px] object-contain z-[1] pt-[19px] pb-[44px]'
          />
          <button
            onClick={() => setIsOpen(true)}
            className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
          >
            <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
              카공이의 다양한 모습들
            </span>
          </button>
          <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#907E6D] to-[#75624F]'></div>
          <div className='absolute w-[212px] h-[38px] rounded-[100%] bg-[#83705C] z-0 bottom-[90px] left-0 right-[15px] mx-auto'></div>
        </div>
      </div>
      <div className='relative'>
        <div className='check-gradient-background w-full h-[calc((100vw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-l border-[#F4F4F4] relative shadow-inner-custom'>
          <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
            TURN AROUND
          </span>
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
            spaceBetween={10}
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <img
                  src={character}
                  alt={`카공이_이미지`}
                  className={cn(
                    'h-[38%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*4.05)]'
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src='../imgs/png/company/m_character_section02_bg02.png'
            alt=''
            className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
          />
        </div>
        <div
          onClick={() => swiper?.slidePrev()}
          className={cn(
            'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronLeft
            className='-ml-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
        <div
          onClick={() => swiper?.slideNext()}
          className={cn(
            'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronRight
            className='-mr-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
      </div>
    </section>
  );
};
const MSection04 = ({ setIsOpen }: { setIsOpen: (state: boolean) => void }) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carjipsa_turn01.png',
    '../imgs/png/company/character/carjipsa_turn02.png',
    '../imgs/png/company/character/carjipsa_turn03.png',
    '../imgs/png/company/character/carjipsa_turn04.png',
    '../imgs/png/company/character/carjipsa_turn05.png',
  ];
  return (
    <section className='w-full px-[20px] mb-[50px]'>
      <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#6F8FEC] to-[#375ECD] px-[20px] pt-[33px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32.72px_65.43px_#1111110D]'>
        {/* top */}
        <div className='w-full relative text-white'>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='flex justify-between items-center mb-[19px]'
          >
            <div className='flex justify-start items-center gap-2'>
              <strong className='text-[24px] leading-[24px] font-[700]'>
                카집사
              </strong>
              <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#463A2F] rounded-[100px] py-[10px] px-[12px]'>
                고객 상담사
              </span>
            </div>
            <span className='inline-block text-[14px] leading-[14px] font-[700]'>
              #3 HERO
            </span>
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            className='text-[15px] leading-[24px] font-[400] break-keep'
          >
            고객님의 표정과 음성만 듣고도 무엇을 원하는지 캐치하는 능력의
            소유자. 고객의 질문에 친절히 답하고 그들이 힘들어할 때 곁에서 공감해
            주는 카사요의 진정한 고객 지킴이에요. 고객을 최우선으로 생각하는
            마인드만큼은 세계 최고!
          </motion.p>
        </div>
        {/* bottom */}
        <div className='relative w-full flex flex-col justify-between items-center'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.7 },
            }}
            src='../imgs/png/company/character_carJipsa_img.png'
            alt='카집사 이미지'
            className='w-[calc(100%-180px)] min-w-[70px]  max-w-[103px] object-contain z-[1] pt-[25px] pb-[44px]'
          />
          <button
            onClick={() => setIsOpen(true)}
            className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
          >
            <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
              카집사의 다양한 모습들
            </span>
          </button>
          <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#658BF9] to-[#5074DB]'></div>
          <div className='absolute w-[108px] h-[32px] rounded-[100%] bg-[#567DEB] z-0 bottom-[95px] left-0 right-0 mx-auto'></div>
        </div>
      </div>
      <div className='relative'>
        <div className='check-gradient-background w-full h-[calc((100vw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-l border-[#F4F4F4] relative shadow-inner-custom'>
          <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
            TURN AROUND
          </span>
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
            spaceBetween={10}
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <img
                  src={character}
                  alt={`카집사_이미지`}
                  className={cn(
                    'h-[40%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*3.8)]'
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src='../imgs/png/company/m_character_section02_bg02.png'
            alt=''
            className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
          />
        </div>
        <div
          onClick={() => swiper?.slidePrev()}
          className={cn(
            'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronLeft
            className='-ml-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
        <div
          onClick={() => swiper?.slideNext()}
          className={cn(
            'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronRight
            className='-mr-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
      </div>
    </section>
  );
};
const MCarkeyPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carkey01.png',
    '../imgs/png/company/character/carkey02.png',
    '../imgs/png/company/character/carkey03.png',
    '../imgs/png/company/character/carkey04.png',
    '../imgs/png/company/character/carkey05.png',
    '../imgs/png/company/character/carkey06.png',
  ];
  return (
    <MDialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <MDialogContent className='w-[90%] rounded-[16px] bg-white'>
        <MDialogHeader>
          <MDialogTitle className='text-center font-[700] text-[20px] leading-[32px] mb-[14px]'>
            딜러이자 경영자,
            <br />
            카키의 다양한 모습이에요.
          </MDialogTitle>
        </MDialogHeader>
        <div className='relative w-full overflow-hidden rounded-b-[16px]'>
          <Swiper
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full'
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <div className='bg-[#EAF6F0] h-[335px] w-full'>
                  <img
                    src={character}
                    alt={`카키_이미지`}
                    className={cn(
                      'h-[48%] object-contain relative top-[45%] -translate-y-[50%] left-[50%] -translate-x-[50%]',
                      index === 5 && 'h-[58%]',
                      index === 2 && 'h-[55%]'
                    )}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </MDialogContent>
    </MDialog>
  );
};
const MCarGongPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carGong01.png',
    '../imgs/png/company/character/carGong02.png',
    '../imgs/png/company/character/carGong03.png',
    '../imgs/png/company/character/carGong04.png',
    '../imgs/png/company/character/carGong05.png',
    '../imgs/png/company/character/carGong06.png',
  ];
  return (
    <MDialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <MDialogContent className='w-[90%] rounded-[16px] bg-white'>
        <MDialogHeader>
          <MDialogTitle className='text-center font-[700] text-[20px] leading-[32px] mb-[14px]'>
            정비사, <br />
            카공이의 다양한 모습이에요.
          </MDialogTitle>
        </MDialogHeader>
        <div className='relative w-full overflow-hidden rounded-b-[16px]'>
          <Swiper
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full'
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <div className='bg-[#F2EDE9] h-[335px] w-full'>
                  <img
                    src={character}
                    alt={`카공이_이미지`}
                    className={cn(
                      'h-[48%] object-contain relative top-[45%] -translate-y-[50%] left-[50%] -translate-x-[50%]'
                    )}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </MDialogContent>
    </MDialog>
  );
};
const MCarJipsaPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) => {
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const characterList = [
    '../imgs/png/company/character/carjipsa01.png',
    '../imgs/png/company/character/carjipsa02.png',
    '../imgs/png/company/character/carjipsa03.png',
    '../imgs/png/company/character/carjipsa04.png',
    '../imgs/png/company/character/carjipsa05.png',
    '../imgs/png/company/character/carjipsa06.png',
  ];
  return (
    <MDialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <MDialogContent className='w-[90%] rounded-[16px] bg-white'>
        <MDialogHeader>
          <MDialogTitle className='text-center font-[700] text-[20px] leading-[32px] mb-[14px]'>
            고객 상담사,
            <br />
            카집사의 다양한 모습이에요.
          </MDialogTitle>
        </MDialogHeader>
        <div className='relative w-full overflow-hidden rounded-b-[16px]'>
          <Swiper
            loop={true}
            speed={1000}
            slidesPerView={1}
            className='characterSwiper w-full'
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSlideChange={(swiper: SwiperClass) => {
              setPage(Math.ceil(swiper.activeIndex + 1));
            }}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {characterList.map((character, index) => (
              <SwiperSlide
                key={`characterSwiper_slide_${index}`}
                className='w-full !h-auto'
              >
                <div className='bg-[#EEF2FF] h-[335px] w-full'>
                  <img
                    src={character}
                    alt={`카집사_이미지`}
                    className={cn(
                      'h-[48%] object-contain relative top-[45%] -translate-y-[50%] left-[50%] -translate-x-[50%]'
                    )}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden'
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </MDialogContent>
    </MDialog>
  );
};

export default function Character() {
  // pc
  const [isCarkeyOpen, setIsCarkeyOpen] = useState(false);
  const [isCarGongOpen, setIsCarGongOpen] = useState(false);
  const [isCarJipsaOpen, setIsCarJipsaOpen] = useState(false);
  // 모바일
  const [isMCarkeyOpen, setIsMCarkeyOpen] = useState(false);
  const [isMCarGongOpen, setIsMCarGongOpen] = useState(false);
  const [isMCarJipsaOpen, setIsMCarJipsaOpen] = useState(false);
  return (
    <>
      <BrowserView className='pb-[100px]'>
        <Section01 />
        <Section02 setIsOpen={setIsCarkeyOpen} />
        <Section03 setIsOpen={setIsCarGongOpen} />
        <Section04 setIsOpen={setIsCarJipsaOpen} />
        <CarkeyPopup isOpen={isCarkeyOpen} setIsOpen={setIsCarkeyOpen} />
        <CarGongPopup isOpen={isCarGongOpen} setIsOpen={setIsCarGongOpen} />
        <CarJipsaPopup isOpen={isCarJipsaOpen} setIsOpen={setIsCarJipsaOpen} />
      </BrowserView>
      <MobileView className='w-dvw'>
        <MSection01 />
        <MSection02 setIsOpen={setIsMCarkeyOpen} />
        <MSection03 setIsOpen={setIsMCarGongOpen} />
        <MSection04 setIsOpen={setIsMCarJipsaOpen} />
        <MCarkeyPopup isOpen={isMCarkeyOpen} setIsOpen={setIsMCarkeyOpen} />
        <MCarGongPopup isOpen={isMCarGongOpen} setIsOpen={setIsMCarGongOpen} />
        <MCarJipsaPopup
          isOpen={isMCarJipsaOpen}
          setIsOpen={setIsMCarJipsaOpen}
        />
      </MobileView>
    </>
  );
}

import { isBrowser, isMobile } from 'react-device-detect';
import { BrowserMediaStory } from './browser';
import { MobileMediaStory } from './mobile';

export default function MediaStory() {
  return (
    <>
      {isBrowser && <BrowserMediaStory />}
      {isMobile && <MobileMediaStory />}
    </>
  );
}

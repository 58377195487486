import { motion } from 'framer-motion';
import { cn } from 'util/index';
import { list } from '../main';

export default function Section02() {
  return (
    <section className='w-[1200px] pt-[100px] pb-[40px]'>
      <div className='w-full h-full flex flex-col justify-start items-start gap-6'>
        <motion.h3
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700]'
        >
          카사요는 왜 다른가요?
        </motion.h3>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 1 },
          }}
          className='w-full grid grid-cols-3 bg-[#F8F9FA]'
        >
          {list.map((item, index) => (
            <li
              key={`section02List_${item.title}`}
              className={cn(
                'p-[30px] min-h-[260px] flex flex-col justify-start items-start relative gap-4 border-[#EEEEEE] border-r',
                (index === 2 || index === 5) && 'border-r-0',
                index > 2 && 'border-t'
              )}
            >
              <span className='mb-1 min-w-[46px] text-[15px] leading-[15px] font-[700] inline-flex justify-center items-center py-[6px] px-[11px] bg-[#222] rounded-[100px] text-white'>
                0{index + 1}
              </span>
              <div>
                <strong className='inline-block text-[20px] leading-[30px] text-[#000] font-[600]'>
                  {item.title}
                </strong>
                {item.point && (
                  <span className='mt-[5.5px] flex justify-start items-center pl-2 text-[#000] border-[#222] border-l-[3px] text-[16px] leading-[19px] font-[500]'>
                    {item.point}
                  </span>
                )}
              </div>

              <p className='whitespace-pre-line'>{item.description}</p>
              <div className='flex flex-col justify-center items-center gap-[12px] absolute bottom-[25px] right-[26px]'>
                <img
                  className='h-[58px] object-contain'
                  src={item.imgUrl}
                  alt={`${item.title}_이미지`}
                />
                <img
                  src='../imgs/png/main_section02_shadow.png'
                  alt='그림자'
                  className='w-[60px] object-contain'
                />
              </div>
            </li>
          ))}
        </motion.ul>
      </div>
    </section>
  );
}

import { Outlet } from 'react-router-dom';

import { isBrowser } from 'react-device-detect';
import { useEffect, useState } from 'react';

export default function CarsayoCompany() {
  const [loadedImages, setLoadedImages] = useState<Array<HTMLImageElement>>([]);
  const imgUrls: Array<string> = [
    '../imgs/png/company/ceo_section03_img01.png',
    '../imgs/png/company/ceo_section03_img02.png',
    '../imgs/png/company/ceo_section03_img03.png',
    '../imgs/png/company/ceo_section03_img04.png',
    '../imgs/png/company/ceo_section03_img05.png',
    '../imgs/png/company/ceo_section03_img06.png',
    '../imgs/png/company/ceo_section04_img01.png',
    '../imgs/png/company/ceo_section04_img02.png',
    '../imgs/png/company/ceo_section04_img03_02.png',
    '../imgs/png/company/ceo_section04_img03.png',
    '../imgs/png/company/ceo_section04_img04.png',
    '../imgs/png/company/ceo_section04_img05.png',
    '../imgs/png/company/ceo_section01_sign.png',
    '../imgs/png/company/ceo_section01_bg.png',
    '../imgs/svg/company/ceo_section01_img.svg',
    '../imgs/png/company/overview_section01_bg.png',
    '../imgs/png/company/overview_section02_bg.png',
    '../imgs/png/company/character_section01_item01.png',
    '../imgs/png/company/philosophy_section01_bg.png',
    '../imgs/png/company/philosophy_section01_bg02.png',
  ];
  useEffect(() => {
    const preloadImages = () => {
      const images = imgUrls.map((url) => {
        const img = new Image();
        img.src = url.replace('url("', '').replace('")', '');
        return img;
      });
      setLoadedImages(images);
    };
    preloadImages();
  }, []);
  return (
    <div className={isBrowser ? `w-full min-w-[1260px]` : 'w-[100dvw]'}>
      <Outlet />
    </div>
  );
}

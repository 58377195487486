import { motion } from 'framer-motion';

export default function Section01() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 1 },
      }}
      style={{
        backgroundImage: 'url(/imgs/png/main01_bg02.png)',
      }}
      className='w-[1200px] h-[460px] bg-no-repeat bg-cover bg-bottom'
    >
      <div className='w-full h-full flex flex-col justify-start items-start p-[60px] gap-6'>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[40px] leading-[60px] font-[700]'
        >
          자동차의 모든 것<br />
          <strong className='inline-block px-[5px] bg-[#222] leading-[50px] text-white font-[700] mr-1'>
            카사요
          </strong>
          에서 쉽고 간편하게
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.5 },
          }}
          className='text-[18px] leading-[28.8px]'
        >
          국내 최대 네트워크 인프라를 사용하는 유일한 플랫폼으로
          <br />
          신차 구매(리스/장기렌트, 일시불, 할부), 내 차 팔기, 다이렉트 자동차
          보험,
          <br />
          자동차 사고수리를 한 번에 끝낼 수 있습니다.
        </motion.p>
      </div>
    </motion.section>
  );
}

import { X } from 'lucide-react';
import AppleIcon from '../common/icons/AppleIcon';
import GoogleIcon from '../common/icons/GoogleIcon';
import { isAndroid, isBrowser, isIOS, isMobile } from 'react-device-detect';
import { LinkUrl } from 'common/data/links';
import { Link } from 'react-router-dom';

import { create } from 'zustand';

interface TopBannerStore {
  isTopBannerOpen: boolean; // 상태 타입
  setIsTopBannerOpen: (value: boolean) => void; // 함수 타입
}
export const useTopBannerStore = create<TopBannerStore>((set) => ({
  isTopBannerOpen: true, // 초기값
  setIsTopBannerOpen: (value: boolean) => set({ isTopBannerOpen: value }),
}));

function BrowserTop() {
  const { isTopBannerOpen, setIsTopBannerOpen } = useTopBannerStore();
  const toggleBanner = () => setIsTopBannerOpen(!isTopBannerOpen);

  if (!isTopBannerOpen) return null;

  return (
    <div
      className={
        'flex-none w-full min-w-[1260px] bg-[#0F1236] sticky top-0 left-0 text-white z-10'
      }
    >
      <div className='w-full h-[72px] flex justify-center items-center p-4 gap-[30px]'>
        <p className='font-[400]'>새롭게 바뀐 앱으로 간편하게 로그인하세요</p>
        <div className='flex justify-center items-center gap-2.5'>
          <Link
            to={LinkUrl.앱.앱스토어}
            target='_blank'
            className='flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
          >
            <AppleIcon />
            <span className='text-[14px] leading-[14px]'>App Store</span>
          </Link>
          <Link
            to={LinkUrl.앱.플레이스토어}
            target='_blank'
            className='flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
          >
            <GoogleIcon />
            <span className='text-[14px] leading-[14px]'>Play Store</span>
          </Link>
        </div>
      </div>
      <button
        onClick={toggleBanner}
        className='absolute top-4 my-auto right-[40px]'
      >
        <X width={40} height={40} strokeWidth={1} color='#fff' />
      </button>
    </div>
  );
}

function MobileTop() {
  const { isTopBannerOpen, setIsTopBannerOpen } = useTopBannerStore();
  const toggleBanner = () => setIsTopBannerOpen(!isTopBannerOpen);

  if (!isTopBannerOpen) return null;

  return (
    <div
      className={
        'flex-none flex justify-between items-center px-5 tiny:px-4 gap-4 tiny:gap-3 w-full bg-[#0F1236] h-[60px] sticky top-0 left-0 text-white z-10'
      }
    >
      <div className='text-white font-light text-[16px] leading-[16px] tracking-[-0.4px] tiny:text-[14px] tiny:tracking-[-0.6px]'>
        간편하게 로그인하세요
      </div>

      <div className='flex gap-4 tiny:gap-3'>
        {isIOS && (
          <Link
            to={LinkUrl.앱.앱스토어}
            target='_blank'
            className='flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
          >
            <AppleIcon />
            <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px]'>
              App Store
            </div>
          </Link>
        )}

        {isAndroid && (
          <Link
            to={LinkUrl.앱.플레이스토어}
            target='_blank'
            className='flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
          >
            <GoogleIcon />
            <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px]'>
              Play Store
            </div>
          </Link>
        )}

        <button onClick={toggleBanner} className=''>
          <X width={30} height={30} strokeWidth={1.5} color='#fff' />
        </button>
      </div>
    </div>
  );
}

export default function TopBanner() {
  return (
    <>
      {isBrowser && <BrowserTop />}
      {isMobile && <MobileTop />}
    </>
  );
}

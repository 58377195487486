import busImgs from './bus';
import radioContents from './radio';

function RadioBox() {
  return (
    <div className='grid grid-cols-5 gap-4'>
      {radioContents.map((el, index) => {
        return (
          <div key={index} className='flex flex-col w-full h-full pb-[40px]'>
            <img src={el.imgUrl} className='pb-6' />
            <div className='text-[#222222] text-[18px] font-medium leading-[25.2px] tracking-[-0.36px] pb-1.5'>
              {el.title}
            </div>
            <div className='text-[#222222] text-[16px] font-normal leading-[22.4px] tracking-[-0.32px]'>
              {el.description}
            </div>
          </div>
        );
      })}
    </div>
  );
}

/** 24시간 인기 라디오 프로그램 CM 송출 */
function Section01() {
  return (
    <div className='w-[1200px] space-y-4'>
      <div className='text-[#6E77F6] text-[20px] font-medium leading-[20px] tracking-[-0.5px]'>
        Story 01
      </div>
      <div className='pb-2 text-[#222222] text-[32px] font-bold leading-[48px] tracking-[-0.8px]'>
        24시간 인기 라디오 프로그램 CM 송출
      </div>
      <div className='pb-6 text-[#222222] text-[18px] font-normal leading-[28.8px] tracking-[-0.45px]'>
        우리의 자동차 생활에 특별한 헤택을 제공하는 현금 120만원 캐시백 이벤트
        <br />
        지금 바로 카사요 GIFT 광고를 CM으로 들을 수 있습니다.
      </div>

      <RadioBox />
    </div>
  );
}

function BusBox() {
  return (
    <div className='grid grid-cols-2 gap-5'>
      {busImgs.map((el, index) => {
        return (
          <div key={index} className='flex flex-col w-full h-full'>
            <img src={el} className='rounded-[10px]' />
          </div>
        );
      })}
    </div>
  );
}

/** 전국 버스 외부광고 */
function Section02() {
  return (
    <div className='w-[1200px] space-y-4'>
      <div className='text-[#6E77F6] text-[20px] font-medium leading-[20px] tracking-[-0.5px]'>
        Story 02
      </div>
      <div className='pb-2 text-[#222222] text-[32px] font-bold leading-[48px] tracking-[-0.8px]'>
        전국 버스 외부광고
      </div>
      <div className='pb-6 text-[#222222] text-[18px] font-normal leading-[28.8px] tracking-[-0.45px]'>
        카사요가 제공하는 연중 최대 프로모션 - 현금 120만원 캐시백 이벤트!
        <br />
        서울은 물론, 전국 시도 광역버스 외부광고입니다.
      </div>

      <BusBox />
    </div>
  );
}

export function BrowserMediaStory() {
  return (
    <div
      style={{
        backgroundImage: 'url("/imgs/png/media/bg_media_1.png")',
      }}
      className='flex flex-col items-center justify-center py-[60px] space-y-20 bg-cover'
    >
      <Section01 />
      <Section02 />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { cn } from '../../util/index';
import { isBrowser } from 'react-device-detect';
import { ArrowUp } from 'lucide-react';

export default function GoTopBtn() {
  const [isShow, setIsShow] = useState(false);

  const scrollToTop = () => {
    const mainPageElement = document.getElementById(
      'main'
    ) as HTMLElement | null;

    if (mainPageElement) {
      mainPageElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const mainPageElement = document.getElementById(
      'main'
    ) as HTMLElement | null;

    const handleScroll = () => {
      if (mainPageElement) {
        if (isBrowser) {
          setIsShow(mainPageElement.scrollTop > 500);
        } else {
          const scrollTop = mainPageElement.scrollTop;
          const scrollHeight = mainPageElement.scrollHeight;
          const clientHeight = mainPageElement.clientHeight;

          setIsShow(
            scrollTop > 500 && scrollTop + clientHeight < scrollHeight - 100
          );
        }
      }
    };

    if (mainPageElement) {
      mainPageElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainPageElement) {
        mainPageElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      {isShow && (
        <div
          onClick={scrollToTop}
          className={cn(
            'fixed bottom-5 right-5 cursor-pointer z-10 flex items-center justify-center rounded-full border-[0.5px] border-black border-opacity-20 bg-black bg-opacity-50 p-2.5',
            isBrowser && 'w-[80px] h-[80px]'
          )}
        >
          <ArrowUp
            width={isBrowser ? 33 : 22}
            height={isBrowser ? 33 : 22}
            className='text-white'
          />
        </div>
      )}
    </>
  );
}

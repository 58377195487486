import busImgs from './bus';
import radioContents from './radio';
import { motion } from 'framer-motion';

function RadioBox() {
  return (
    <div className='grid grid-cols-2 gap-[9px]'>
      {radioContents.map((el) => {
        return (
          <div className='flex flex-col w-full h-full pb-[30px]'>
            <img src={el.imgUrl} className='pb-3 rounded-[8px]' />
            <div className='text-[#222] text-[15px] font-medium leading-[21px] tracking-[-0.3px] pb-1'>
              {el.title}
            </div>
            <div className='text-[#222] text-[14px] font-normal leading-[19.6px] tracking-[-0.28px]'>
              {el.description}
            </div>
          </div>
        );
      })}
    </div>
  );
}

/** 24시간 인기 라디오 프로그램 CM 송출 */
function Section01() {
  return (
    <div className='space-y-4'>
      <div className='space-y-1.5'>
        <div className='text-[#6E77F6] text-[14px] font-semibold leading-[14px] tracking-[-0.35px]'>
          Story 01
        </div>
        <div className='pb-2 text-[#222] text-[20px] tiny:text-[18px] font-semibold leading-[30px] tracking-[-0.5px]'>
          24시간 인기 라디오 프로그램 CM 송출
        </div>
      </div>
      <div className='pb-2 text-[#222] text-[16px] tiny:text-[15px] font-normal leading-[25.6px] tracking-[-0.4px]'>
        우리의 자동차 생활에 특별한 헤택을 제공하는 <br />
        현금 120만원 캐시백 이벤트 지금 바로 <br />
        카사요 GIFT 광고를 CM으로 들을 수 있습니다.
      </div>

      <RadioBox />
    </div>
  );
}

function BusBox() {
  return (
    <div className='grid grid-cols-1 gap-2.5'>
      {busImgs.map((el) => {
        return (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='flex flex-col w-full h-full'
          >
            <img src={el} className='rounded-[8px]' />
          </motion.div>
        );
      })}
    </div>
  );
}

/** 전국 버스 외부광고 */
function Section02() {
  return (
    <div className='mt-5 space-y-4'>
      <div className='space-y-1.5'>
        <div className='text-[#6E77F6] text-[14px] font-semibold leading-[14px] tracking-[-0.35px]'>
          Story 02
        </div>
        <div className='pb-2 text-[#222] text-[20px] tiny:text-[18px] font-semibold leading-[30px] tracking-[-0.5px]'>
          전국 버스 외부광고
        </div>
      </div>
      <div className='pb-2 text-[#222] text-[16px] tiny:text-[15px] font-normal leading-[25.6px] tracking-[-0.4px]'>
        카사요가 제공하는 연중 최대 프로모션 <br />
        - 현금 120만원 캐시백 이벤트! <br />
        서울은 물론, 전국 시도 광역버스 외부광고입니다.
      </div>

      <BusBox />
    </div>
  );
}

export function MobileMediaStory() {
  return (
    <div className='w-dvw py-[50px] px-5'>
      <Section01 />
      <Section02 />
    </div>
  );
}

import { motion } from 'framer-motion';

export default function MSection01() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 0.7 },
      }}
      className='w-w-[100dvw] h-[484px] relative tiny:h-[calc(434px)]'
    >
      <img
        src='../imgs/png/m_main01_bg.png'
        alt='배경 이미지'
        className='absolute -z-[10] left-0 right-0 mx-auto bottom-0 w-full h-full object-cover'
      />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { delay: 0, duration: 0.5 },
        }}
        className='break-keep py-[60px] px-[20px] text-[24px] leading-[36px] font-[700]'
      >
        자동차의 모든 것<br />
        <strong className='bg-[#222] text-white px-1 font-[700]'>
          카사요
        </strong>{' '}
        에서 쉽고 간편하게
      </motion.div>
      <div className='w-[80px] h-[80px] object-contain absolute bottom-[40%] left-[25%] rounded-full backdrop-blur-[12.8px]'>
        <img
          src='../imgs/png/m_main01_itemImg.png'
          alt='자동차 아이콘 이미지'
          className='w-full object-cover'
        />
      </div>
    </motion.section>
  );
}

import { cn } from 'util/index';
import { list } from '../main';
import { motion } from 'framer-motion';

export default function MSection03() {
  return (
    <section className='overflow-hidden w-[100dvw]'>
      <div className='w-full pt-[89px]'>
        <span className='px-[20px] text-[15px] leading-[15px] font-[600] text-[#6E77F6] block mb-4'>
          간편한 서비스
        </span>
        <div className='px-[20px] text-[20px] leading-[30px] font-[600]'>
          신차 구매와 내 차 팔기,
          <br />
          자동차 금융/보험/사고수리까지 한번에
        </div>
        <div className='flex flex-col justify-center items-center relative pb-[50px]'>
          <div className='overflow-hidden text-center'>
            <img
              src='../imgs/png/m_main_section03_mockup01.png'
              alt='목업1'
              className='w-full aspect-[1125/2427] -mt-[16%]'
            />
          </div>
          <div className='-mt-[35%] w-full overflow-x-auto no-scrollbar'>
            <ul className='inline-flex justify-start items-center gap-[14px] shrink-0'>
              <li className='w-[calc(100vw-106px)] ml-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img01.png'
                  alt='서비스리스트이미지1'
                  className='w-full h-full object-cover'
                />
              </li>
              <li className='w-[calc(100vw-106px)]  mr-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img02.png'
                  alt='서비스리스트이미지2'
                  className='w-full h-full object-cover'
                />
              </li>
            </ul>
          </div>
          <div className='absolute -z-[10] bg-[#F8F9FA] w-full h-[65%] bottom-0 left-0'></div>
        </div>
      </div>
      <div className='w-full pt-[50px]'>
        <span className='px-[20px] text-[15px] leading-[15px] font-[600] text-[#6E77F6] block mb-4'>
          네트워크 인프라
        </span>
        <div className='px-[20px] text-[20px] leading-[30px] font-[600]'>
          국내 최대 네트워크
          <br />
          인프라 보유 플랫폼
        </div>
        <div className='flex flex-col justify-center items-center relative pb-[50px]'>
          <div className='overflow-hidden text-center'>
            <img
              src='../imgs/png/m_main_section03_mockup02.png'
              alt='목업2'
              className='w-full aspect-[1125/2427] -mt-[16%]'
            />
          </div>
          <div className='-mt-[35%] w-full overflow-x-auto no-scrollbar'>
            <ul className='inline-flex justify-start items-center gap-[14px] shrink-0'>
              <li className='w-[calc(100vw-106px)] ml-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img03.png'
                  alt='네트워크인프라리스트이미지1'
                  className='w-full object-cover'
                />
              </li>
              <li className='w-[calc(100vw-106px)] mr-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img04_new.png'
                  alt='네트워크인프라리스트이미지2'
                  className='w-full h-full object-cover'
                />
              </li>
            </ul>
          </div>
          <div className='absolute -z-[10] bg-[#F8F9FA] w-full h-[65%] bottom-0 left-0'></div>
        </div>
      </div>
      <div className='w-full pt-[50px]'>
        <span className='px-[20px] text-[15px] leading-[15px] font-[600] text-[#6E77F6] block mb-4'>
          자동차 사고수리 서비스
        </span>
        <div className='px-[20px] text-[20px] leading-[30px] font-[600]'>
          어떤 상황이든 안전을 최우선으로
          <br />
          실시간 수리 과정 확인 및 출고, A/S까지
        </div>
        <div className='flex flex-col justify-center items-center relative pb-[50px]'>
          <div className='overflow-hidden text-center'>
            <img
              src='../imgs/png/m_main_section03_mockup03.png'
              alt='목업3'
              className='w-full aspect-[1125/2427] -mt-[16%]'
            />
          </div>
          <div className='-mt-[35%] w-full overflow-x-auto no-scrollbar'>
            <ul className='inline-flex justify-start items-center gap-[14px] shrink-0'>
              <li className='w-[calc(100vw-106px)] ml-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img05.png'
                  alt='자동차사고수리서비스이미지1'
                  className='w-full object-cover'
                />
              </li>
              <li className='w-[calc(100vw-106px)] mr-[53px] aspect-[270/300]'>
                <img
                  src='../imgs/png/main_section03_img06.png'
                  alt='자동차사고수리서비스이미지2'
                  className='w-full h-full object-cover'
                />
              </li>
            </ul>
          </div>
          <div className='absolute -z-[10] bg-[#F8F9FA] w-full h-[65%] bottom-0 left-0'></div>
        </div>
      </div>
    </section>
  );
}

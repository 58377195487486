import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { cn } from 'util/index';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Link, useLocation } from 'react-router';

const reportList = [
  {
    title: `"저서 세일즈 신화를 만드는 힘" - 한국 경제 신문 출판`,
    imgUrl: '../imgs/png/company/ceo_section03_img01.png',
  },
  {
    title: `세계 3대 인명 사전 'Marquis Who's Who'에 'marketing professional consultant'로 등재`,
    imgUrl: '../imgs/png/company/ceo_section03_img02.png',
  },
  {
    title: `세계 3대 인명 사전 'IBC, International Biographical Centre'에 'Leading Professionals of the World'로 등재`,
    imgUrl: '../imgs/png/company/ceo_section03_img03.png',
  },
  {
    title: `IBK 중소기업 CEO Report 칼럼 기고`,
    imgUrl: '../imgs/png/company/ceo_section03_img04.png',
  },
  {
    title: `[2013 한국 현대인물열전 33선] 한국인물연구원 (한국현대인물열전편찬회)`,
    imgUrl: '../imgs/png/company/ceo_section03_img05.png',
  },
  {
    title: `대한민국 세일즈 최고 기록(한국 기록원) 각 세일즈 분야 대상 수상 기록 최다 보유자`,
    imgUrl: '../imgs/png/company/ceo_section03_img06.png',
  },
];

const awardsList = [
  {
    year: `2010년`,
    title: 'INSURANCE MDRT',
    imgs: [
      {
        url: '../imgs/png/company/ceo_section04_img01.png',
        imgWidth: 'w-[60px]',
      },
    ],
  },
  {
    year: `2004년 ~ 2006년`,
    title: 'LEXUS 판매왕',
    imgs: [
      {
        url: '../imgs/png/company/ceo_section04_img02.png',
        imgWidth: 'w-[60px]',
      },
    ],
  },
  {
    year: `2003년`,
    title: 'GM(Cadilac, Saab) 판매왕',
    imgs: [
      {
        url: '../imgs/png/company/ceo_section04_img03_02.png',
        imgWidth: 'w-[44px]',
      },
      {
        url: '../imgs/png/company/ceo_section04_img03.png',
        imgWidth: 'w-[50px]',
      },
    ],
  },
  {
    year: `2002년`,
    title: 'BMW 판매왕',
    imgs: [
      {
        url: '../imgs/png/company/ceo_section04_img04.png',
        imgWidth: 'w-[44px]',
      },
    ],
  },
  {
    year: `2000년 ~ 2001년`,
    title: 'DAEWOO 판매왕',
    imgs: [
      {
        url: '../imgs/png/company/ceo_section04_img05.png',
        imgWidth: 'w-[62px]',
      },
    ],
  },
];

const newsList = [
  {
    category: '중앙일보',
    title: '카사요, 소비자중심경영 인증 획득',
    link: 'https://www.joongang.co.kr/article/25298873',
  },
  {
    category: 'YTN 방송 방영',
    title: '판매왕의 비결 “사람이 재산” ',
    link: null,
  },
  {
    category: 'SBS 방송 방영',
    title: '수입차 판매왕이 말하다',
    link: null,
  },
  {
    category: '한국경제 지면 & 온라인 보도',
    title: '실적보다 중요한 건 고객과의 인연',
    link: null,
  },
  {
    category: '매일경제 지면 & 온라인 보도',
    title: '3일에 1대 판매, 수입차 판매왕들의 비법은?',
    link: null,
  },
];

// pc
const Section01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section
      className={cn(
        'w-[1200px] pb-[50px] min-h-[604px] mx-auto flex justify-center items-stretch transition-opacity duration-500',
        layoutLoad ? 'opacity-100' : 'opacity-20'
      )}
    >
      <div className='w-[620px] flex flex-col justify-between items-start bg-[#F8F9FA] py-[60px] px-[50px]'>
        <div>
          <strong className='mb-[30px] block text-[24px] leading-[38.4px] font-[600] text-[#000]'>
            1999년부터 26년간 금융 무사고
            <br />
            국산차 및 수입차 4대 브랜드 최고 세일즈 기록
          </strong>
          <p className='text-[#000] text-[16px] leading-[25.6px]'>
            1999년부터 26년간 금융사고 없이 <br />
            국산차 및 수입차 4대 브랜드에서 최고의 세일즈 기록을 달성하며 <br />
            고객의 안전과 편의를 최우선으로 최적의 조건과 혜택을 제시했습니다.
            <br />
            [카사요]는 국내 유일의 자동차 쇼핑 통합 플랫폼으로, <br />
            업계 선도자로서의 자부심과 책임감을 가지고 있습니다.
            <br />
            앞으로도 카사요는 고객의 권익을 보호하고 고객과 신뢰를 쌓아가는{' '}
            <br />
            기업이 될 것을 약속드립니다.
            <br />
            여러분의 지속적인 관심과 성원을 부탁드립니다.
            <br />
            감사합니다.
          </p>
        </div>
        <div className='w-full flex justify-end items-center gap-4'>
          <span className='text-[#000] leading-[16px] font-[600]'>
            카사요(주) 대표이사
          </span>
          <img
            src='../imgs/png/company/ceo_section01_sign.png'
            alt='대표사인'
            className='h-[52px] object-contain'
          />
        </div>
      </div>

      <div
        style={{
          backgroundImage: 'url(../imgs/png/company/ceo_section01_bg.png)',
        }}
        className='w-[580px] bg-no-repeat bg-cover bg-bottom flex justify-center items-center'
      >
        <img
          src='../imgs/svg/company/ceo_section01_img.svg'
          alt='카사요 사업 소개 이미지'
          className='w-[463px] object-contain'
        />
      </div>
    </section>
  );
};

const Section02 = () => {
  return (
    <section className='w-[1200px] mx-auto py-[50px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          주요 경력
        </motion.span>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700]'
        >
          국내 · 수입 자동차 <br />
          판매 통합 시스템 총괄 컨설턴트
        </motion.strong>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='w-full grid grid-cols-3 gap-[21px] min-h-[272px]'
      >
        <div className='flex flex-start items-end w-full relative z-0 overflow-hidden'>
          <img
            src='../imgs/png/company/ceo_section02_bg01.png'
            alt='주요경력이미지1'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-[20px] font-[500]'>
            자동차 브랜드 대상 세일즈 / 마케팅 / 직원
            <br />
            서비스 컨설팅 및 교육
          </div>
        </div>
        <div className='flex flex-start items-end w-full relative z-0 overflow-hidden'>
          <img
            src='../imgs/png/company/ceo_section02_bg02.png'
            alt='주요경력이미지2'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-[20px] font-[500]'>
            구 LG카드(현 신한카드) 리스 총괄 <br />
            컨설팅 등 자동차 금융 설계 최고 경력
          </div>
        </div>
        <div className='flex flex-start items-end w-full relative z-0 overflow-hidden'>
          <img
            src='../imgs/png/company/ceo_section02_bg03.png'
            alt='주요경력이미지3'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-[20px] font-[500]'>
            서비스 TOP 브랜드(LEXUS) 4년 연속
            <br />
            고객 관리 최고 평점 기록
          </div>
        </div>
      </motion.div>
    </section>
  );
};

const Section03 = () => {
  const [currentImg, setCurrentImg] = useState(reportList[0].imgUrl);

  return (
    <section className='w-[1200px] mx-auto py-[50px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          주요 기록
        </motion.span>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='w-full flex justify-start items-center gap-[48px]'
      >
        <AnimatePresence mode='wait'>
          <motion.div
            key={currentImg}
            initial={{ opacity: 0, scale: 0.97 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.03 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className='flex justify-center items-center w-[386px] h-[320px] shrink-0'
          >
            <img
              src={currentImg}
              alt='주요기록이미지'
              className='w-full h-full object-cover transition-all'
            />
          </motion.div>
        </AnimatePresence>
        <ul className='flex flex-col justify-start items-start cursor-pointer'>
          {reportList.map((item, index) => (
            <li
              onClick={() => setCurrentImg(item.imgUrl)}
              key={`${item.title}_${index}`}
              className={cn(
                '!cursor-pointer transition-color duration-300 py-2 text-[18px] leading-[28.8px] font-[600] text-[#999999] hover:pl-1 whitespace-nowrap',
                currentImg === item.imgUrl && 'text-[#222]'
              )}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </motion.div>
    </section>
  );
};

const Section04 = () => {
  return (
    <section className='py-[50px]'>
      <div className='w-[1200px] mx-auto  mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          수상 기록
        </motion.span>
      </div>
      <div className='w-full bg-[#F8F9FA]'>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='w-[1200px] mx-auto grid grid-cols-5 divide-x-[1px] border-[#EEEEEE] border-x '
        >
          {awardsList.map((item, index) => (
            <li
              className='flex flex-col justify-start items-start p-[20px] pt-[40px] gap-[36px]'
              key={item.title}
            >
              <div className='flex flex-col justify-start items-start gap-[10px] text-[18px] leading-[27px]'>
                <strong className='font-[700]'>{item.year}</strong>
                <span className='font-[500]'>{item.title}</span>
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                {item.imgs.map((imgs) => (
                  <div className='flex justify-center items-center self-end w-[80px] h-[80px] rounded-[12px] bg-white'>
                    <img
                      src={imgs.url}
                      alt='수상기록브랜드아이콘'
                      className={`object-contain ${imgs.imgWidth}`}
                    />
                  </div>
                ))}
              </div>
            </li>
          ))}
        </motion.ul>
      </div>
    </section>
  );
};

const Section05 = () => {
  return (
    <section className='pt-[50px] pb-[60px]'>
      <div className='w-[1200px] mx-auto  mb-[40px] flex flex-col justify-start items-start'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          보도 자료
        </motion.span>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='w-[1200px] mx-auto flex flex-col justify-start items-start border-t border-[#EEEEEE]'
      >
        {newsList
          .filter((list) => list.link !== null)
          .map((item, index) => (
            <li
              className='w-full flex justify-start items-center border-b border-[#EEEEEE] shadow-[0px_4px_20px_#0000001A]'
              key={item.title}
            >
              <Link
                to={item.link ?? ''}
                target='_blank'
                rel='noopener noreferrer'
                className='w-full flex justify-start items-center py-[30px] pl-[30px] pr-[6 5px]  gap-[34px] relative'
              >
                <span className={cn('text-[18px] leading-[27px] relative')}>
                  <img
                    src='../imgs/icons/news_link_icon.png'
                    alt='고정아이콘'
                    className='h-[30px] object-contain'
                  />
                </span>
                <div>
                  <span className='inline-block pr-[12px] border-r border-[#CCCCCC] text-[18px] leading-[18px] font-[600]'>
                    {item.category}
                  </span>
                  <span className='pl-[12px] text-[18px] leading-[27px] '>
                    {item.title}
                  </span>
                </div>
                <img
                  src='../imgs/icons/right-up-arrow.png'
                  alt='우측 화살표'
                  className='w-[40px] object-contain absolute top-0 bottom-0 my-auto right-[30px]'
                />
              </Link>
            </li>
          ))}
        {newsList
          .filter((list) => list.link === null)
          .map((item, index) => (
            <li
              className={cn(
                'w-full flex justify-start items-start p-[30px] gap-[34px] border-b border-[#EEEEEE]',
                index === 0 && 'border-t'
              )}
              key={item.title}
            >
              <span className='text-[18px] leading-[27px] inline-block min-w-[30px] text-center'>
                0{index + 1}
              </span>
              <div>
                <span className='inline-block pr-[12px] border-r border-[#CCCCCC] text-[18px] leading-[18px] font-[600]'>
                  {item.category}
                </span>
                <span className='pl-[12px] text-[18px] leading-[27px] '>
                  {item.title}
                </span>
              </div>
            </li>
          ))}
      </motion.ul>
    </section>
  );
};

// 모바일
const MSection01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section
      className={cn(
        'w-full flex flex-col justify-start items-start transition-opacity duration-500',
        layoutLoad ? 'opacity-100' : 'opacity-20'
      )}
    >
      <div
        style={{
          backgroundImage: 'url(../imgs/png/company/ceo_section01_bg.png)',
        }}
        className='w-full aspect-[375/417] bg-no-repeat bg-cover bg-bottom flex justify-center items-center'
      >
        <img
          src='../imgs/png/company/ceo_section01_img.png'
          alt='카사요 사업 소개 이미지'
          className='w-[90%] object-contain'
        />
      </div>
      <div className='w-full flex flex-col justify-between items-start bg-[#F8F9FA] py-[50px] px-[20px]'>
        <div>
          <strong className='mb-4 block text-[18px] leading-[28.8px] font-[600] text-[#000] break-keep'>
            1999년부터 26년간 금융 무사고
            <br />
            국산차 및 수입차 4대 브랜드 최고 세일즈 기록
          </strong>
          <p className='text-[#000] text-[16px] leading-[25.6px] break-keep mb-[50px] max-w-[335px]'>
            1999년부터 26년간 금융사고 없이 <br />
            국산차 및 수입차 4대 브랜드에서 최고의 세일즈 기록을 달성하며 고객의
            안전과 편의를 최우선으로 최적의 조건과 혜택을 제시했습니다. <br />
            [카사요]는 국내 유일의 자동차 쇼핑 통합 플랫폼으로, 업계
            선도자로서의 자부심과 책임감을 가지고 있습니다. 앞으로도 카사요는
            고객의 권익을 보호하고 고객과 신뢰를 쌓아가는 기업이 될 것을
            약속드립니다. <br />
            여러분의 지속적인 관심과 성원을 부탁드립니다.
            <br />
            감사합니다.
          </p>
        </div>
        <div className='w-full flex justify-end items-center gap-4'>
          <span className='text-[#000] text-[15px] leading-[15px] font-[600]'>
            카사요(주) 대표이사
          </span>
          <img
            src='../imgs/png/company/ceo_section01_sign.png'
            alt='대표사인'
            className='h-[52px] object-contain'
          />
        </div>
      </div>
    </section>
  );
};

const MSection02 = () => {
  return (
    <section className='pt-[50px] px-[20px]'>
      <div className='mb-[30px] flex flex-col justify-start items-start gap-[6px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          주요 경력
        </motion.span>
        <motion.strong
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          className='text-[20px] leading-[30px] font-[600]'
        >
          국내 · 수입 자동차 <br />
          판매 통합 시스템 총괄 컨설턴트
        </motion.strong>
      </div>
      <div className='w-full flex flex-col justify-start items-start gap-[20px]'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex flex-start items-end w-full relative z-0 overflow-hidden aspect-[335/236]'
        >
          <img
            src='../imgs/png/company/ceo_section02_bg01.png'
            alt='주요경력이미지1'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-4 font-[500] text-[15px] leading-[24px]'>
            자동차 브랜드 대상 세일즈 / 마케팅 / 직원
            <br />
            서비스 컨설팅 및 교육
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.05, duration: 0.7 },
          }}
          className='flex flex-start items-end w-full relative z-0 overflow-hidden aspect-[335/236]'
        >
          <img
            src='../imgs/png/company/ceo_section02_bg02.png'
            alt='주요경력이미지2'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-4 font-[500] text-[15px] leading-[24px]'>
            구 LG카드(현 신한카드) 리스 총괄 <br />
            컨설팅 등 자동차 금융 설계 최고 경력
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 0.7 },
          }}
          className='flex flex-start items-end w-full relative z-0 overflow-hidden aspect-[335/236]'
        >
          <img
            src='../imgs/png/company/ceo_section02_bg03.png'
            alt='주요경력이미지3'
            className='absolute -z-[10] left-0 top-0 w-full h-full object-cover hover:scale-[1.1] transition-all duration-300'
          />
          <div className='bg-[#00000066] backdrop-blur-[20px] text-white text-center w-full p-4 font-[500] text-[15px] leading-[24px]'>
            서비스 TOP 브랜드(LEXUS) 4년 연속
            <br />
            고객 관리 최고 평점 기록
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const MSection03 = () => {
  const [currentText, setCurrentText] = useState(reportList[0].imgUrl);
  const [page, setPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (swiper?.realIndex) {
      setCurrentText(reportList[swiper.realIndex].title);
    } else {
      setCurrentText(reportList[0].title);
    }
  }, [swiper?.realIndex]);

  return (
    <section className='w-full pt-[50px]'>
      <div className='mb-[30px] px-[20px] flex flex-col justify-end items-start'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          주요 기록
        </motion.span>
      </div>
      <div className='w-full flex justify-center items-center relative'>
        <Swiper
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop={true}
          speed={1000}
          slidesPerView={1}
          className='recordSwiper w-full'
          onTransitionStart={() => {
            setIsAnimating(true);
          }}
          onTransitionEnd={() => {
            setIsAnimating(false);
          }}
          onSlideChange={(swiper: SwiperClass) => {
            setPage(Math.ceil(swiper.activeIndex + 1));
          }}
          onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          centeredSlides={true}
        >
          {reportList.map((record, index) => (
            <SwiperSlide
              key={`recordSwiper_slide_${index}`}
              className='w-full !h-auto'
            >
              <img
                src={record.imgUrl}
                alt={`${record.title}_이미지`}
                className='w-full aspect-[375/320] object-cover'
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          onClick={() => swiper?.slidePrev()}
          className={cn(
            'absolute left-[10px] top-[50%] -translate-y-[50%] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronLeft
            className='-ml-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
        <div
          onClick={() => swiper?.slideNext()}
          className={cn(
            'absolute right-[10px] top-[50%] -translate-y-[50%] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
            isAnimating && 'hidden'
          )}
        >
          <ChevronRight
            className='-mr-[2px]'
            width={26}
            height={26}
            strokeWidth={2}
            stroke={'#fff'}
          />
        </div>
      </div>
      <div className='flex flex-col justify-start items-start px-[20px] mt-6 h-[90px]'>
        <strong className='text-[20px] leading-[30px] font-[600] break-keep'>
          {currentText}
        </strong>
      </div>
    </section>
  );
};

const MSection04 = () => {
  return (
    <section className='pt-[50px] px-[20px]'>
      <div className='mb-[30px] flex flex-col justify-start items-start gap-[6px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          수상 기록
        </motion.span>
      </div>
      <div className='w-full bg-[#F8F9FA]'>
        <ul className='w-full flex flex-col justify-start items-start'>
          {awardsList.map((item, index) => (
            <motion.li
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              className='w-full flex flex-col justify-start items-start p-[20px] gap-[10px] border-b border-[#EEEEEE]'
              key={item.title}
            >
              <div className='flex flex-col justify-start items-start gap-[10px] text-[18px] leading-[27px]'>
                <motion.strong
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0, duration: 0.5 },
                  }}
                  className='font-[700]'
                >
                  {item.year}
                </motion.strong>
                <motion.span
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.1, duration: 0.5 },
                  }}
                  className='font-[500]'
                >
                  {item.title}
                </motion.span>
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                {item.imgs.map((imgs) => (
                  <div className='flex justify-center items-center self-end w-[80px] h-[80px] rounded-[12px] bg-white'>
                    <img
                      src={imgs.url}
                      alt='수상기록브랜드아이콘'
                      className={`object-contain ${imgs.imgWidth}`}
                    />
                  </div>
                ))}
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const MSection05 = () => {
  return (
    <section className='py-[50px]'>
      <div className='mb-[30px] px-[20px] flex flex-col justify-start items-start gap-[6px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          보도 자료
        </motion.span>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='w-full mx-auto flex flex-col justify-start items-start border-t border-[#EEEEEE]'
      >
        {newsList
          .filter((list) => list.link !== null)
          .map((item, index) => (
            <li
              className='w-full flex justify-start items-center border-b border-[#EEEEEE] shadow-[0px_4px_20px_#0000001A]'
              key={item.title}
            >
              <Link
                to={item.link ?? ''}
                target='_blank'
                rel='noopener noreferrer'
                className='w-full flex justify-start items-center py-[18px] pl-[20px] pr-[55px] gap-[40px] relative'
              >
                <span
                  className={cn(
                    'text-[18px] leading-[27px] relative',
                    'after:content-[""] after:absolute after:w-[1px] after:h-[16px] after:bg-[#CCCCCC] after:top-0 after:bottom-0 after:my-auto after:-right-[20px]'
                  )}
                >
                  <img
                    src='../imgs/icons/news_link_icon.png'
                    alt='고정아이콘'
                    className='h-[20px] object-contain'
                  />
                </span>
                <div className='flex flex-col justify-start items-start gap-[6px]'>
                  <span className='inline-block text-[16px] leading-[24px] font-[600]'>
                    {item.category}
                  </span>
                  <span className='text-[16px] leading-[24px] break-keep'>
                    {item.title}
                  </span>
                </div>
                <img
                  src='../imgs/icons/right-arrow.png'
                  alt='우측 화살표'
                  className='w-[30px] object-contain absolute top-0 bottom-0 my-auto right-[20px]'
                />
              </Link>
            </li>
          ))}
        {newsList
          .filter((list) => list.link === null)
          .map((item, index) => (
            <li
              className={cn(
                'w-full flex justify-start items-center py-[18px] px-[20px] gap-[40px] border-b border-[#EEEEEE]',
                index === 0 && 'border-t'
              )}
              key={item.title}
            >
              <span
                className={cn(
                  'text-[18px] leading-[27px] relative',
                  'after:content-[""] after:absolute after:w-[1px] after:h-[16px] after:bg-[#CCCCCC] after:top-0 after:bottom-0 after:my-auto after:-right-[20px]'
                )}
              >
                0{index + 1}
              </span>
              <div className='flex flex-col justify-start items-start gap-[6px]'>
                <span className='inline-block text-[16px] leading-[24px] font-[600]'>
                  {item.category}
                </span>
                <span className='text-[16px] leading-[24px] break-keep'>
                  {item.title}
                </span>
              </div>
            </li>
          ))}
      </motion.ul>
    </section>
  );
};

export default function Ceo() {
  return (
    <>
      <BrowserView className='pt-[50px] pb-[40px]'>
        <Section01 />
        <Section02 />
        <Section03 />
        <Section04 />
        <Section05 />
      </BrowserView>
      <MobileView className='w-dvw'>
        <MSection01 />
        <MSection02 />
        <MSection03 />
        <MSection04 />
        <MSection05 />
      </MobileView>
    </>
  );
}

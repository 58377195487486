export default function GoogleIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.36967 2.31738C3.11284 2.62105 2.98178 3.01141 3.00335 3.40855V20.649C2.97683 21.0468 3.10849 21.439 3.36967 21.7402L3.42423 21.8025L13.0811 12.1457V11.9119L3.42423 2.26282L3.36967 2.31738Z'
        fill='url(#paint0_linear_61_2740)'
      />
      <path
        d='M16.2769 15.3639L13.0813 12.145V11.9111L16.2769 8.6922L16.347 8.73897L20.1739 10.9057C21.2651 11.5214 21.2651 12.5347 20.1739 13.1582L16.3626 15.3249L16.2769 15.3639Z'
        fill='url(#paint1_linear_61_2740)'
      />
      <path
        d='M16.3699 15.3188L13.0808 12.0297L3.36938 21.7411C3.58962 21.9371 3.87153 22.0499 4.16621 22.0599C4.4609 22.0698 4.74978 21.9763 4.98276 21.7956L16.3699 15.3188Z'
        fill='url(#paint2_linear_61_2740)'
      />
      <path
        d='M16.3699 8.74003L4.98276 2.27096C4.75129 2.08773 4.46264 1.99197 4.16755 2.00053C3.87247 2.00908 3.58985 2.12139 3.36938 2.31772L13.0808 12.0291L16.3699 8.74003Z'
        fill='url(#paint3_linear_61_2740)'
      />
      <path
        opacity='0.2'
        d='M16.2764 15.2487L4.98276 21.6788C4.75824 21.8482 4.48463 21.9399 4.20335 21.9399C3.92207 21.9399 3.64846 21.8482 3.42394 21.6788L3.36938 21.7333L3.42394 21.7957C3.64846 21.9651 3.92207 22.0568 4.20335 22.0568C4.48463 22.0568 4.75824 21.9651 4.98276 21.7957L16.3699 15.3188L16.2764 15.2487Z'
        fill='black'
      />
      <path
        opacity='0.12'
        d='M3.36963 21.6313C3.11928 21.3221 2.99659 20.9289 3.02669 20.5323V20.6492C3.00017 21.047 3.13182 21.4392 3.39301 21.7404L3.44757 21.6858L3.36963 21.6313Z'
        fill='black'
      />
      <path
        opacity='0.12'
        d='M20.1732 13.0429L16.2761 15.2486L16.3463 15.3188L20.1732 13.152C20.3991 13.0565 20.595 12.9018 20.7404 12.7043C20.8858 12.5068 20.9752 12.2737 20.9993 12.0297C20.9506 12.2515 20.8504 12.4587 20.7069 12.6347C20.5634 12.8107 20.3806 12.9505 20.1732 13.0429Z'
        fill='black'
      />
      <path
        opacity='0.25'
        d='M4.98311 2.3795L20.1737 11.0153C20.3812 11.1077 20.564 11.2475 20.7075 11.4235C20.851 11.5995 20.9512 11.8068 20.9999 12.0286C20.9758 11.7845 20.8864 11.5514 20.741 11.3539C20.5956 11.1564 20.3996 11.0017 20.1737 10.9062L4.98311 2.27038C3.89974 1.64686 3.00342 2.16127 3.00342 3.40832V3.52523C3.0268 2.27818 3.89974 1.76377 4.98311 2.3795Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_61_2740'
          x1='12.2237'
          y1='3.22928'
          x2='-0.85471'
          y2='16.3077'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00A0FF' />
          <stop offset='0.01' stopColor='#00A1FF' />
          <stop offset='0.26' stopColor='#00BEFF' />
          <stop offset='0.51' stopColor='#00D2FF' />
          <stop offset='0.76' stopColor='#00DFFF' />
          <stop offset='1' stopColor='#00E3FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_61_2740'
          x1='21.6002'
          y1='12.0281'
          x2='2.74637'
          y2='12.0281'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFE000' />
          <stop offset='0.41' stopColor='#FFBD00' />
          <stop offset='0.78' stopColor='#FFA500' />
          <stop offset='1' stopColor='#FF9C00' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_61_2740'
          x1='14.585'
          y1='13.8223'
          x2='-3.15425'
          y2='31.5538'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF3A44' />
          <stop offset='1' stopColor='#C31162' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_61_2740'
          x1='0.922049'
          y1='-3.41871'
          x2='8.84082'
          y2='4.50006'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#32A071' />
          <stop offset='0.07' stopColor='#2DA771' />
          <stop offset='0.48' stopColor='#15CF74' />
          <stop offset='0.8' stopColor='#06E775' />
          <stop offset='1' stopColor='#00F076' />
        </linearGradient>
      </defs>
    </svg>
  );
}

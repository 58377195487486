export default function CarsayoLogo({
  fill,
  width,
}: {
  fill?: string;
  width?: string;
}) {
  return (
    <svg
      width={width || '120'}
      height='20'
      viewBox='0 0 120 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_42_272)'>
        <path
          d='M100.602 1.93924C100.602 0.884235 99.7768 0 98.6826 0C98.1639 0 97.5572 0.24626 97.2009 0.695052L92.2849 6.59609C92.1754 6.72727 91.9738 6.72727 91.8647 6.59609L87.8246 0.752129C87.4122 0.252704 86.8368 0.00644419 86.2366 0.00644419C85.1112 0.00644419 84.2048 0.890679 84.2048 2.05984C84.2048 2.53349 84.3176 2.97583 84.6739 3.41818L89.6281 10.3797C89.8315 10.6251 89.9429 10.9335 89.9429 11.2525V17.8794C89.9429 19.0168 90.8746 19.9581 91.9995 19.9581C93.1245 19.9581 94.0566 19.0168 94.0566 17.8794V11.0564C94.0566 10.7379 94.168 10.429 94.3715 10.1837L100.171 3.19033C100.471 2.83038 100.609 2.44465 100.609 1.94568L100.602 1.93924Z'
          fill={fill || 'black'}
        />
        <path
          d='M109.767 0C104.182 0 99.7881 4.40506 99.7881 9.97883C99.7881 15.5526 104.182 19.9577 109.767 19.9577C115.352 19.9577 119.746 15.5526 119.746 9.97883C119.746 4.40506 115.352 0 109.767 0ZM109.767 15.9383C106.59 15.9383 104.074 13.3031 104.074 9.97883C104.074 6.65455 106.589 4.01933 109.767 4.01933C112.944 4.01933 115.459 6.65455 115.459 9.97883C115.459 13.3031 112.944 15.9383 109.767 15.9383Z'
          fill={fill || 'black'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 9.97883C0 4.41933 4.40736 0 9.97883 0C12.1837 0 14.2219 0.695052 15.8753 1.89459C16.7917 2.56018 16.9952 3.84212 16.33 4.75857C15.6649 5.67503 14.3825 5.87848 13.4661 5.21335C12.5063 4.51692 11.3091 4.10081 9.97883 4.10081C6.67526 4.10081 4.10081 6.68124 4.10081 9.97883C4.10081 13.2764 6.67526 15.8568 9.97883 15.8568C11.3096 15.8568 12.5063 15.4412 13.4661 14.7443C14.3825 14.0792 15.6649 14.2826 16.33 15.1995C16.9952 16.116 16.7917 17.3984 15.8753 18.0635C14.2219 19.2635 12.1841 19.9581 9.97883 19.9581C4.40736 19.9577 0 15.5388 0 9.97883Z'
          fill={fill || 'black'}
        />
        <path
          d='M61.2271 19.9577C57.6253 19.9577 55.4508 18.3807 54.7604 16.3885C54.6108 15.9876 54.5417 15.5733 54.5417 15.1724C54.5417 13.9425 55.3012 13.1139 56.3714 13.1139C57.1885 13.1139 57.7404 13.4481 58.2812 14.4106C58.8451 15.6133 59.9613 16.1215 61.2957 16.1215C62.9413 16.1215 64.0115 15.2663 64.0115 14.0898C64.0115 13.0205 63.3095 12.4723 61.3422 12.0313L59.6276 11.6566C56.3254 10.9349 54.5993 9.04994 54.5993 6.10909C54.5993 2.39264 57.3726 0 61.1926 0C64.3682 0 66.6923 1.4168 67.4982 3.86329C67.6129 4.19747 67.6709 4.5183 67.6709 4.91922C67.6709 6.0557 66.9574 6.81749 65.8987 6.83084C64.9781 6.83084 64.4032 6.44327 63.9079 5.50748C63.3671 4.30426 62.4465 3.83659 61.2271 3.83659C59.6966 3.83659 58.7991 4.6389 58.7991 5.77491C58.7991 6.79079 59.547 7.3519 61.3537 7.75328L63.0568 8.1275C66.6007 8.91646 68.2117 10.5873 68.2117 13.6216C68.2117 17.4983 65.5995 19.9577 61.2271 19.9577Z'
          fill={fill || 'black'}
        />
        <path
          d='M25.9245 1.18161C26.4032 0.157904 27.623 -0.285364 28.6495 0.192426C29.676 0.669757 30.1197 1.88679 29.6414 2.91095L22.0341 18.7761C21.5554 19.7998 20.3356 20.2431 19.3091 19.7653C18.2826 19.2879 17.8384 18.0709 18.3172 17.0467L25.9245 1.18161Z'
          fill={fill || 'black'}
        />
        <path
          d='M29.6419 1.18161C29.1636 0.157904 27.9434 -0.285364 26.9169 0.192426C25.8904 0.669757 25.4467 1.88679 25.9254 2.91095L33.5328 18.7761C34.011 19.7998 35.2313 20.2431 36.2577 19.7653C37.2842 19.2879 37.7279 18.0709 37.2497 17.0467L29.6419 1.18161Z'
          fill={fill || 'black'}
        />
        <path
          d='M29.6091 15.8569H35.541V19.9577H29.6091C28.5103 19.9577 27.6123 19.0422 27.6123 17.9071C27.6123 16.772 28.5039 15.8564 29.6091 15.8564V15.8569Z'
          fill={fill || 'black'}
        />
        <path
          d='M76.695 1.18161C77.1733 0.157904 78.3936 -0.285364 79.42 0.192426C80.4465 0.669757 80.8902 1.88679 80.4115 2.91095L72.8041 18.7761C72.3259 19.7998 71.1056 20.2431 70.0792 19.7653C69.0527 19.2879 68.609 18.0709 69.0872 17.0467L76.695 1.18161Z'
          fill={fill || 'black'}
        />
        <path
          d='M80.4123 1.18161C79.9336 0.157904 78.7138 -0.285364 77.6873 0.192426C76.6609 0.669757 76.2171 1.88679 76.6954 2.91095L84.3027 18.7761C84.7815 19.7998 86.0013 20.2431 87.0277 19.7653C88.0542 19.2879 88.4984 18.0709 88.0197 17.0467L80.4123 1.18161Z'
          fill={fill || 'black'}
        />
        <path
          d='M80.3791 15.8569H86.311V19.9577H80.3791C79.2804 19.9577 78.3823 19.0422 78.3823 17.9071C78.3823 16.772 79.2739 15.8564 80.3791 15.8564V15.8569Z'
          fill={fill || 'black'}
        />
        <path
          d='M41.147 19.9521C42.2664 19.9521 43.193 18.9979 43.193 17.8448V4.91965C43.193 4.3162 43.6828 3.82644 44.2867 3.82644H46.6871C47.8871 3.82644 48.9371 4.77696 48.9371 6.01286C48.9371 7.10193 48.3258 7.92862 47.2321 8.06257C47.3688 8.2292 50.3042 11.072 50.3042 11.072C51.8029 10.457 53.0927 8.85152 53.0927 6.18041C53.0927 2.77926 50.5306 -0.000488281 46.8179 -0.000488281H41.141C40.0216 -0.000488281 39.095 0.953712 39.095 2.10676V17.8379C39.095 18.991 40.0216 19.9452 41.141 19.9452L41.147 19.9516V19.9521Z'
          fill={fill || 'black'}
        />
        <path
          d='M49.1695 9.01083L50.3041 11.0721L51.2611 13.1227L53.0926 16.9501C53.6491 17.9139 53.3057 19.1544 52.3248 19.7206C51.3444 20.2863 50.0979 19.9641 49.5409 18.9998L45.6178 11.0601C45.0774 10.1248 45.4291 8.90312 46.4118 8.33558C47.389 7.77171 48.6259 8.06952 49.1695 9.01037V9.01083Z'
          fill={fill || 'black'}
        />
      </g>
      <defs>
        <clipPath id='clip0_42_272'>
          <rect width='120' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

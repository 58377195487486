/** 수정 시 협회(fks-intro)에서도 수정되어야 합니다. */
const radioContents: {
  title: string;
  description: string;
  imgUrl: string;
}[] = [
  {
    title: '두시탈출 컬투쇼',
    description: '[월~일] 14:00~16:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg01.png',
  },
  {
    title: '웬디의 영스트리트',
    description: '[월~일] 20:00~22:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg02.png',
  },
  {
    title: '김영철의 파워FM',
    description: '[월~일] 07:00~09:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg03.png',
  },
  {
    title: '황제성의 황제파워',
    description: '[월~일] 16:00~18:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg04.png',
  },
  {
    title: '박소현의 러브게임',
    description: '[월~일] 18:00~20:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg05.png',
  },
  {
    title: '딘딘의 뮤직하이',
    description: '[월~일] 23:00~01:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg06.png',
  },
  {
    title: '이인권의 펀펀투데이',
    description: '[월~일] 05:00~07:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg07.png',
  },
  {
    title: '박하선의 씨네타운',
    description: '[월~일] 11:00~12:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg08.png',
  },
  {
    title: '아름다운 이 아침, 봉태규입니다',
    description: '[월~일] 09:00~11:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg09.png',
  },
  {
    title: '12시엔 주현영',
    description: '[월~일] 12:00~14:00',
    imgUrl:
      'https://carsayo-public.s3.ap-northeast-2.amazonaws.com/resource/fks/teaser/radio-adImg10.png',
  },
];

export default radioContents;
